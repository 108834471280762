import React, { Fragment, useState, useEffect } from "react";



import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as invitecodesActionCreators from "../../data/invitecodes/actions";

import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TextField from '@mui/material/TextField';

import * as DataSource from './DataSource';
import List from './List';


const InviteCodes = (props) => {


  useEffect(() => {
    props.actions.invitecodes.findItems({ query: DataSource.query });
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleNew = () => {
    setCurrentItem({});
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };


  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Códigos de invitación" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Códigos de invitación"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo código</HeaderButton>
            </Fragment>
          )}
        />
        <List
          loading={props.invitecodes.loading}
          items={props.invitecodes.items}
          total={props.invitecodes.total}
          handleNew={handleNew}
          handleCreate={props.actions.invitecodes.create}
          handleEdit={handleEdit}
          handlePatch={(item) => props.actions.invitecodes.patch(item, item)}
          handleRemove={props.actions.invitecodes.remove}
          handleRemoveMany={props.actions.invitecodes.removeMany}
          defaultLang={props.defaultLang}
          langs={props.selectedApp.langPattern.available}
          selectedApp={props.selectedApp}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear código"
        editTitle="Editar código"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.invitecodes.create}
        patchAction={props.actions.invitecodes.patch}
        manifest={DataSource.modalManifest}
        actions={props.actions.invitecodes}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    invitecodes: state.data.invitecodes,
  }),
  (dispatch) => ({
    actions: {
      invitecodes: bindActionCreators(invitecodesActionCreators, dispatch),
    },
  })
)(InviteCodes);
