import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";


export default props => {


  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar códigos?",
          description: `Esta acción eliminará por completo los
          códigos, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => { });
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'code',
          header: 'Código'
        }
      ]}
      importFormatter={(items) => {
        return items;
      }}
      humanize={(item) => `${item.name}`}
      importHandler={true}
      // IMPORT EXPORT END
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "code",
          headerName: "Código",
          flex: 2
        },
        {
          field: "userId",
          headerName: "Usado",
          flex: 1,
          valueGetter: (params) => params.row.userId ? 'Si' : 'No',
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar código?",
                      description: `Esta acción eliminará por completo el
                      código, así como todo su contenido asociado.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => { });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "Añade tu primer código",
        body:
          "Añade códigos a tu app.",
        cta: "+ Crear nuevo código",
        action: props.handleNew,
      }}
    />
  );
}