
export const query = {
  $aggregation: [
  ],
  $sort: { createdAt: 1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "code",
      type: "string",
      label: "Código",
    },
  ]
];