
export const query = {
  $aggregation: [
    {
      '$lookup': {
        'from': 'admins',
        'localField': 'adminId',
        'foreignField': '_id',
        'as': 'admin'
      }
    },
    {
      '$unwind': {
        'path': '$admin',
        'includeArrayIndex': 'string',
        'preserveNullAndEmptyArrays': true
      }
    }
  ],
  $sort: { createdAt: -1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "type",
      type: "select",
      options: [
        { key: 'Demo', label: 'Demo' },
        { key: 'Lite', label: 'Lite' },
        { key: 'Plus', label: 'Plus' },
        { key: 'Enterprise', label: 'Enterprise' },

      ],
      label: "Tipo de licencia",
    }
  ],
  [
    {
      key: "estimateNumber",
      type: "string",
      label: "Nº presupuesto",
    }
  ],
  [
    {
      key: "adminId",
      type: "admin",
      label: "Gestor",
    }
  ],
  [
    {
      key: "status",
      type: "select",
      options: [
        { key: 'Pending', label: 'Pending' },
        { key: 'Contacted', label: 'Contacted' },
        { key: 'Accepted', label: 'Accepted' },
        { key: 'Cancelled', label: 'Cancelled' },

      ],
      label: "Estado de la venta",
    },
  ]
];