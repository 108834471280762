import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton, Grid } from "@mui/material/";
import { RotateLeft, DeleteForever, Edit as EditIcon, Done as DoneIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as interactionsActionCreators from "../../data/interactions/actions";
import FeathersHelper from '../../helpers/feathers';

import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import { ScaledImage } from "../../components/ScaledImage";

const BASE_QUERY = {
  $limit: 10,
  $sort: { createdAt: -1 }
};

const Interactions = (props) => {

  let { moduleId } = useParams();

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [rankedUsers, setRankedUsers] = useState(null)
  const [allUsers, setAllUsers] = useState([])


  const MODAL_MANIFEST = [
    [
      {
        key: "userId",
        type: "autocomplete",
        options: allUsers,
        label: "Usuario",
        help: "Usuario al que aplicar los puntos.",
      }
    ],
    [
      {
        key: "points",
        type: "string",
        label: "Puntos",
        help: "Puntos a aplicar. Positivos o negativos.",
      }
    ],
    [
      {
        key: "comments",
        type: "text",
        label: "Comentarios",
        help: "Comentarios sobre los puntos aplicados.",
      }
    ]
  ];
  const MODAL_LANG_MANIFEST = [
  ];

  const loadRanking = () => {
    FeathersHelper.find("users", {
      query: {
        points: { $gt: 0 },
        $sort: {
          points: -1
        }
      }
    }).then( r => setRankedUsers(r));
  }

  const loadAllUsers = () => {
    FeathersHelper.find("users", {
      query: {
        $limit: -1,
        $sort: {
          lastName: 1,
          firstName: 1
        }
      }
    }).then( r => setAllUsers(r.map(u => ({ key: u._id, label: `${u.firstName} ${u.lastName}` }))));
  }

  const loadItems = () => {
    props.actions.interactions.findItems({
      query: {
        ...BASE_QUERY,
      }
    });
  }

  useEffect(() => {
    loadAllUsers();
    loadRanking();
    loadItems();
  }, []);

  useEffect(() => {
    loadAllUsers();
    loadRanking();
  }, [props.data.interactions.items]);



  const handleNew = () => {
    setCurrentItem({
      interactionType: 'custom'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const handleReset = () => {
    confirm({
        title: "¿Reiniciar ranking?",
        description: `Esta acción pondrá todos los puntos a 0.
        Esta acción es permenente y no tiene marcha atrás.`,
        confirmationText: "Reiniciar",
        confirmationButtonProps: { variant: "contained" },
        cancellationText: "Cancelar",
        cancellationButtonProps: { variant: "contained" },
      })
        .then(() => {
          FeathersHelper.remove("rankings", null, {
            query: {
              appId: props.selectedApp.slug
            }
          }).then( r => {
            window.location.reload();
          });
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
  }

  const confirm = useConfirm();
  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Game centre" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Game centre"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nueva interacción</HeaderButton>
              <HeaderButton onClick={handleReset}>Reiniciar ranking</HeaderButton>
            </Fragment>
          )}
        />
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <DataView
              items={props.data.interactions.items}
              total={props.data.interactions.total}
              loadPage={(page, perPage) => props.actions.interactions.findItems({ query: {
                ...BASE_QUERY,
                $limit: 10,
                $skip: (perPage * page)
              } })}
              columnDefs={[
                { ref: "createdAt", align: "left" },
                { ref: "user", align: "left" },
                { ref: "interactionType", align: "left" },
                { ref: "points", align: "left" },
                { ref: "comments", align: "left" },
                { ref: "deleted", align: "left" },
                { ref: "actions", align: "left" },
              ]}
              renderHeader={(ref) => {
                switch (ref) {
                   case "createdAt":
                    return "Fecha";
                  case "user":
                    return "Usuario";
                  case "interactionType":
                    return "Tipo";
                  case "points":
                    return "Puntos";
                  case "comments":
                    return "Comentarios";
                  case "deleted":
                    return "Activa";
                  case "actions":
                    return "Acciones";
                  default:
                    return null;
                }
              }}
              renderCell={(ref, item) => {
                switch (ref) {
                  case "createdAt":
                    return nativeMoment(item[ref]).format('L HH:mm');
                  case "user":
                    const users = allUsers.filter(u => u.key.toString() == item.userId.toString());
                    return users.length > 0 ? `${users[0].label}` : 'Anónimo';
                  case "interactionType":
                    return (
                      <Fragment>
                        {item.interactionType}
                        {item.interactionSubtype ?
                          <Fragment><br /> {item.interactionSubtype}</Fragment>
                          :
                          null
                        }
                      </Fragment>
                    );
                  case "points":
                    return item[ref];
                  case "comments":
                    return item[ref];
                  case "deleted":
                    return item.deletedAt ? null : <DoneIcon fontSize="small" />;
                  case "actions":
                    return (
                      <Fragment>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="EditButton"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="DeleteButton"
                          onClick={() => {
                            confirm({
                              title: "Resetear interacción?",
                              description: `Esta acción reseteará la interacción.
                              Sus puntos dejarán de tenerse en cuenta, y sólo quedarán como histórico.`,
                              confirmationText: "Resetear",
                              confirmationButtonProps: { variant: "contained" },
                              cancellationText: "Cancelar",
                              cancellationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                props.actions.interactions.patch(item, {
                                  deletedAt: item.deletedAt ? null : new Date()
                                });
                              })
                              .catch(() => {});
                          }}
                        >
                          <RotateLeft fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="DeleteButton"
                          onClick={() => {
                            confirm({
                              title: "¿Eliminar interacción?",
                              description: `Esta acción eliminará por completo la interacción y no aparecerá.
                              Usar sólo en caso de interacciones erróneas.`,
                              confirmationText: "Eliminar",
                              confirmationButtonProps: { variant: "contained" },
                              cancellationText: "Cancelar",
                              cancellationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                props.actions.interactions.remove(item);
                              })
                              .catch(() => {});
                          }}
                        >
                          <DeleteForever fontSize="small" style={{ color: 'red' }} />
                        </IconButton>
                      </Fragment>
                    );
                  default:
                    return null;
                }
              }}
              empty={{
                title: "Aun no hay interacciones",
                body:
                  "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
                cta: "+ Crear nueva interacción",
                action: handleNew,
              }}
            />
          </Grid>
          <Grid item xs="3">
            {rankedUsers ?
              <div className="UserRanking">
                <div className="Header">
                  <h2>Ranking</h2>
                </div>
                <ol>
                  {rankedUsers.data.map((ru, index) => (
                    <li>
                      <strong>{index+1}</strong>
                      <ScaledImage className="Avatar" imgkey={ru.picture} width={80} height={80} />
                      <span className="Info">
                        <span className="Name">{ru.firstName} {ru.lastName}</span>
                        <span className="Subtitle">{ru.subtitle}</span>
                      </span>
                      <span className="Points">{ru.points}</span>
                    </li>
                  ))}
                </ol>
              </div>
            : null}
          </Grid>
        </Grid>
      </PageScreen>
      <RightModal
        newTitle="Crear interacción"
        editTitle="Editar interacción"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        createAction={(payload) =>
          props.actions.interactions.create(payload).then( r => loadItems() )
        }
        patchAction={(currentItem, payload) =>
          props.actions.interactions.patch(currentItem, payload)
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    data: {
      interactions: state.data.interactions
    },
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      interactions: bindActionCreators(interactionsActionCreators, dispatch),
    },
  })
)(Interactions);
