import React, { Fragment, useState, useEffect } from "react";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";
import FeathersHelper from '../../helpers/feathers';

import { Container, Grid, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress, Box, Select, MenuItem } from "@mui/material/"
import { Alert } from "@mui/lab/"

import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";

import NavBar from '../../components/NavBar'
import PageScreen from '../../components/PageScreen'
import Dropzone from './components/Dropzone';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Modules = (props) => {



  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState(false);
  const [imported, setImported] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [successful, setSuccessful] = useState([]);
  const [failed, setFailed] = useState([]);

  useEffect(() => {
    FeathersHelper.find('audiences', { query: { $limit: -1 } }).then( a => setAudiences(a));
  }, []);

  const handleConfirm = async () => {
    const toImport = imported.slice(1);
    setRecordCount(toImport.length);
    for (var i = 0, len = toImport.length; i < len; i++) {
      const object = {
        appId: props.selectedApp.slug,
        email: toImport[i][0],
        firstName: toImport[i][1],
        lastName: toImport[i][2],
        subtitle: toImport[i][3],
        password: toImport[i][4].toString(),
        //audiences: toImport[i][5].split(",").map(item => item.trim())
        audiences: selectedAudience ? [selectedAudience] : []
      };
      if (toImport[i][5] && toImport[i][5] != '') object.invisible = true;
      await props.actions.users.create(object)
        .then((r,t) => {
          console.log("Success! Result:", t);
          setSuccessful((cs) => [...cs, object]);
        })
        .catch(e => {
          console.log("Error!", e);
          setFailed(cf => [...cf, object]);
        });
    }

  }

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Usuarios", path: `/${props.selectedApp.slug}/users` },
          { title: "Importar" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Box my={2}>
          <Typography component="h3" variant="h3">
            1- Descargar el modelo
          </Typography>
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
          >
            Descargar
                </Button>
        </Box>
        <Typography component="h3" variant="h3">
          2- Asignar audiencia (para todos los usuarios)
        </Typography>
        <Box my={2}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            value={selectedAudience}
            onChange={(e) => setSelectedAudience(e.target.value)}
          >
            <MenuItem value={false} disabled>No asignar audiencia</MenuItem>
            {audiences.map(a => (
              <MenuItem key={a._id} value={a._id}>{a.name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Typography component="h3" variant="h3">
          3- Subir XLSX
              </Typography>
        <Box my={2}>
          <Dropzone setImported={setImported} />
        </Box>
        {imported && (
          <Fragment>
            <Typography component="h3" variant="h3">
              3- Revisar datos
                  </Typography>
            <Box my={4}>
              <TableContainer component={Paper} className="DataTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="left">Apellidos</TableCell>
                      <TableCell align="left">Subtítulo</TableCell>
                      <TableCell align="left">Contraseña</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {imported.slice(1).map((item) => (
                      <TableRow key={item._id}>
                        <TableCell component="th" scope="row">
                          {item[0]}
                        </TableCell>
                        <TableCell>{item[1]}</TableCell>
                        <TableCell>{item[2]}</TableCell>
                        <TableCell>{item[3]}</TableCell>
                        <TableCell>{item[4]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="h3" variant="h3">
              4- Confirmar
                  </Typography>
            <Box my={2}>
              <Button
                onClick={() => handleConfirm()}
                color="primary"
                variant="contained"
                startIcon={<UploadIcon />}
              >
                Confirmar
                    </Button>
            </Box>
            <Box mt={2}>
              <LinearProgressWithLabel
                value={
                  successful.length > 0 || failed.length > 0
                    ? 100 *
                    (((successful.length + failed.length) * 1.0) /
                      recordCount)
                    : 0
                }
              />
            </Box>
            {(successful.length > 0 || failed.length > 0) && (
              <Box my={2}>
                <Alert severity="info">
                  Se han insertado {successful.length} usuarios y han
                        fallado {failed.length} de un total de{" "}
                  {successful.length + failed.length}.
                      </Alert>
              </Box>
            )}
            {failed && failed.length > 0 && (
              <Box my={2}>
                <Alert severity="error">
                  Inserciones fallidas
                        <ul>
                    {failed.map((f) => (
                      <li>{f.email}: el e-mail ya existe</li>
                    ))}
                  </ul>
                </Alert>
              </Box>
            )}
          </Fragment>
        )}
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Modules);
