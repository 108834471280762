import React, { Fragment, useState } from 'react';

import { Routes, Route, NavLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Container,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";

import {
  Menu as MenuIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";

import { Link as RouterLink } from 'react-router-dom';

import FeathersHelper from "../../helpers/feathers";

import { connect } from "react-redux";

import { makeStyles } from "@mui/styles";

import logo from "../../img/uBelong.svg";

const useStyles = makeStyles((theme) => ({
  activeLink: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.primary.main,
    color: "#2A2827 !important",
  },
}));

const NavBar = props => {

  const [anchorEl, setAnchorEl] = useState(false);

  const classes = useStyles();
  const { selected, crumbs } = props;

  const handleUserMenu = (e) => {
    e = e.target || e.srcElement;
    setAnchorEl(e);
  }

  const handleUserMenuClose = () => {
    setAnchorEl(false);
  }

  return (
    <Fragment>
      <AppBar position="sticky" className="AppBar" color="secondary">
        <Container maxWidth="false">
          <Toolbar disableGutters className="Toolbar">
            <img src={logo} className="App-logo" alt="logo" />
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
              className="Breadcrumbs"
              style={{ flex: 1 }}
            >
              {crumbs.map((c,i) => {
                return c.path ? (
                  <RouterLink component={Link} color="inherit" to={c.path} key={`crumbs_${i}`}>
                    {c.title}
                  </RouterLink>
                ) : (
                  <Typography color="textPrimary" key={`crumbs_${i}`}>{c.title}</Typography>
                );
              })}
            </Breadcrumbs>
            <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleUserMenu}>
              {props.app.feathersUser ?
                <React.Fragment>
                  <Avatar alt={`${props.app.feathersUser.firstName} ${props.app.feathersUser.lastName}`}  style={{ marginRight: 8 }}>
                    {props.app.feathersUser.firstName ? props.app.feathersUser.firstName.substr(0,1) : ''}
                    {props.app.feathersUser.lastName ? props.app.feathersUser.lastName.substr(0,1) : ''}
                  </Avatar>
                  {props.app.feathersUser.firstName} {props.app.feathersUser.lastName}
                </React.Fragment>
              : null}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleUserMenuClose}
            >
              {props.app.feathersUser && props.app.feathersUser.master &&
                <React.Fragment>
                  <MenuItem component={RouterLink} to={'/admins'}>Admins</MenuItem>
                  <MenuItem component={RouterLink} to={'/monitor'}>Monitor</MenuItem>
                  <MenuItem component={RouterLink} to={'/atlas'}>Atlas</MenuItem>
                  <MenuItem component={RouterLink} to={'/licenses'}>Licencias</MenuItem>
                  <MenuItem component={RouterLink} to={'/storage'}>Almacenaje</MenuItem>
                  <Divider />
                </React.Fragment>
              }
              <MenuItem onClick={() => FeathersHelper.logOut()}>Logout</MenuItem>

            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      {!props.isHome && (
        <Container maxWidth="false" className="NavBarWrap">
          <nav className="NavBar">
            <NavLink
              to={`/${selected.slug}/`}
              activeClassName={classes.activeLink}
              end
            >
              Módulos
            </NavLink>
            <NavLink
              to={`/${selected.slug}/audiences`}
              activeClassName={classes.activeLink}
              end
            >
              Audiencias
            </NavLink>
            <NavLink
              to={`/${selected.slug}/users`}
              activeClassName={classes.activeLink}
              end
            >
              Usuarios
            </NavLink>
            <NavLink
              to={`/${selected.slug}/invitecodes`}
              activeClassName={classes.activeLink}
              end
            >
              Cod. Invitación
            </NavLink>
            <NavLink
              to={`/${selected.slug}/notifications`}
              activeClassName={classes.activeLink}
              end
            >
              Notificaciones
            </NavLink>
            <NavLink
              to={`/${selected.slug}/announcements`}
              activeClassName={classes.activeLink}
              end
            >
              Destacados
            </NavLink>
            <NavLink
              to={`/${selected.slug}/alerts`}
              activeClassName={classes.activeLink}
              end
            >
              Alertas
            </NavLink>
            <NavLink
              to={`/${selected.slug}/virtual-rooms`}
              activeClassName={classes.activeLink}
              end
            >
              Salas Virtuales
            </NavLink>
            <NavLink
              to={`/${selected.slug}/fastpass`}
              activeClassName={classes.activeLink}
              end
            >
              Fastpass
            </NavLink>
            <NavLink
              to={`/${selected.slug}/gaming`}
              activeClassName={classes.activeLink}
              end
            >
              Game centre
            </NavLink>
            <NavLink
              to={`/${selected.slug}/networking`}
              activeClassName={classes.activeLink}
              end
            >
              Networking
            </NavLink>
            <NavLink
              to={`/${selected.slug}/settings`}
              activeClassName={classes.activeLink}
              end
            >
              Ajustes
            </NavLink>
            <NavLink
              to={`/${selected.slug}/reports`}
              activeClassName={classes.activeLink}
              end
            >
              Reports
            </NavLink>
            <NavLink
              to={`/${selected.slug}/analytics`}
              activeClassName={classes.activeLink}
              end
            >
              Analíticas
            </NavLink>
          </nav>
        </Container>
      )}
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    selected: state.data.apps.selected,
    app: state.services.app
  }),
  null
)(NavBar);