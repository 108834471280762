import React, { useState, useEffect, useRef } from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";

import { scaledImageSource } from "../../../../../../components/ScaledImage";

import FeathersHelper from '../../../../../../helpers/feathers';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { uploadFile } from '../../../../../../helpers/s3';

const useStyles = makeStyles((theme) => ({
  imageDrop: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    overflow: "hidden",
    position: 'relative'
  },
  fileDropInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      maxWidth: '90%'
    },
  },
  imageDropInner: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      width: 90,
      maxWidth: '90%'
    },
  },
}));

export default props => {

  const [uploading, setUploading] = useState(false);
  const [signedUrl, setSignedUrl] = useState(false)

  const classes = useStyles();

  const onDrop = (acceptedFiles) => {

    setUploading(true);

    const file = acceptedFiles[0];

    uploadFile(file).then(r => {
      setUploading(false);
      props.onChange({ target: { value: r.key } })
      setSignedUrl(r.signedResizedURL)

      console.log(`Key is ${r.key}`)
    }).catch(e => {
      console.log("Upload error", e)
      setUploading(false);
    })


  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const value = props.value();

  const wrapperRef = useRef(null);

  useEffect(() => {

  }, [value])

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <div
        className={classes.imageDrop}
        style={{
          paddingTop: `${(1 / props.definition.aspectRatio) * 100}%`,
          position: "relative",
          borderRadius: props.definition.borderRadius,
        }}
        {...getRootProps()}
      >
        <div
          className={classes.imageDropInner}
          ref={wrapperRef}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : value ? null : uploading ? (
            <p>Subiendo...</p>
          ) : (
            <p>Drag 'n' drop or select files</p>
          )}
          {signedUrl || value ?
            <Document file={signedUrl||value} onLoadSuccess={() => { }}>
              <Page height={wrapperRef.current ? wrapperRef.current.clientHeight : 50} pageNumber={1} />
            </Document>
            :
            null
          }
        </div>
      </div>
    </React.Fragment>
  );
};