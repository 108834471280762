import React, { useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import ChipCheckboxGroup from '../ChipCheckboxGroup';

import FeathersHelper from '../../../../helpers/feathers';


const useStyles = makeStyles((theme) => ({
  formBox: {
    border: "1px solid #EEEEEF",
    borderRadius: 10,
  },
  uploadButton: {
    paddingHorizontal: 0,
    minWidth: 0,
  },
  label: {
    textTransform: "uppercase",
    color: "#686E71",
    fontSize: "0.916rem",
  },
  helperText: {
    color: "#B4B4B7",
    fontSize: "0.6875rem",
  },
  error: {
    borderColor: "#FF6666",
    color: "#FF6666",
  },
}));

export default props => {

  const classes = useStyles();

  const [valueObject, setValueObject] = useState({});
  const [options, setOptions] = useState([]);

  useEffect(() => {
    FeathersHelper.find("audiences", { query: { $limit: -1 } }).then( r => {
      setOptions(r);
    }).catch( e => {
      console.log(e);
    });
    setValueObject(props.value);
    //alert(JSON.stringify(props.value));
  }, [props.value]);

  console.log("Inside value", props.value);
  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        className={["MuiInputLabel-shrink", classes.label]}
      >
        {props.definition.label}
      </FormLabel>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        value={props.value.everyone ? "true" : "false"}
        onChange={(e) => {
          props.onChange({
            target: {
              value: {
                everyone: e.target.value == "true",
                audiences:
                  e.target.value == "true" ? valueObject.audiences || [] : [],
              },
            },
          });
          setValueObject({
            everyone: e.target.value == "true",
            audiences:
              e.target.value == "true" ? valueObject.audiences || [] : [],
          });
        }}
      >
        <FormControlLabel
          value={"true"}
          control={<Radio color="primary" />}
          label="Todos los usuarios"
          labelPlacement="left"
        />
        <FormControlLabel
          value={"false"}
          control={<Radio color="primary" />}
          label="Audiencias específicas"
          labelPlacement="left"
        />
      </RadioGroup>
      {props.value.everyone !== false || (
        <ChipCheckboxGroup
          options={options.map(a => ({ _id: a._id, label: a.name }) )}
          value={valueObject.audiences}
          onChange={(v) => {
            props.onChange({
              target: {
                value: {
                  everyone: valueObject.everyone,
                  audiences: v,
                },
              },
            });
            setValueObject({
              everyone: valueObject.everyone,
              audiences: v,
            });
          }}
        />
      )}

      <FormHelperText className={`${classes.helperText} ${
                  props.error ? classes.error : null
                }`}>{props.error || props.definition.help}</FormHelperText>
    </FormControl>
  );
}