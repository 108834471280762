import React, { Fragment, useState, useEffect } from 'react';

import { Grid, Paper, Box, Typography, Button } from "@mui/material/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import Form from "../../components/Form";
import FormField from "../../components/FormField";
import DraggableModules from './components/DraggableModules';

import { ChevronLeft, AccountBalance } from "@mui/icons-material";

import { scaledImageSource } from "../../components/ScaledImage";

import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";

import defaults from '../../defaults.json';

const Wizard = props => {

  const handleScroll = () => {
    const element = document.getElementsByClassName("AppPreview")[0];
    element.setAttribute(
      "style",
      `top: ${Math.max(0,(document.documentElement.scrollTop-35))}px`
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const termsForApp = (locales) => {
    const terms = {};
    locales.map( l => {
      terms[l] = defaults.terms[l]||defaults.terms.default;
    })
    return terms;
  }

  const renderFields = (
    newObject,
    valueForPath,
    setValueForPath,
    erroredFields,
    handleSave
  ) => (
    <Grid container spacing={2}>
      <Grid item md={8}>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Información de la app
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_slug`}
                  error={erroredFields("slug")}
                  definition={{
                    key: "slug",
                    type: "string",
                    label: "URL de la app",
                    help: "Parte de la URL que designará la aplicación. Atención: una vez elegido, no se podrá cambiar.",
                  }}
                  size={12}
                  value={valueForPath("slug")}
                  onChange={(e) => {
                    setValueForPath("slug", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_name`}
                  error={erroredFields("name")}
                  definition={{
                    key: "name",
                    type: "string",
                    label: "Nombre de la app",
                    help: "Nombre interno para identificar la app",
                  }}
                  size={12}
                  value={valueForPath("name")}
                  onChange={(e) => {
                    setValueForPath("name", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_language`}
                  error={erroredFields("langPattern.default")}
                  definition={{
                    key: "name",
                    type: "select",
                    options: defaults.availableLanguages.map(al => ({ key: al.code, label: al.name })),
                    label: "Idioma principal",
                    help:
                      "Podrás elegir otros idiomas una vez publiques tu app",
                  }}
                  size={12}
                  value={valueForPath("default")}
                  onChange={(e) => {
                    setValueForPath("langPattern.available", [e.target.value]);
                    setValueForPath("langPattern.default", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Branding
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_color`}
                  error={erroredFields("color")}
                  definition={{
                    key: "color",
                    type: "color",
                    label: "Color principal",
                    help: "Color que determinará la paleta de la app.",
                  }}
                  size={12}
                  value={valueForPath("color")}
                  onChange={(e) => {
                    setValueForPath("color", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_banner`}
                  error={erroredFields("banner")}
                  definition={{
                    key: "banner",
                    type: "image",
                    aspectRatio: 1.9,
                    borderRadius: 10,
                    width: 1200,
                    height: 640,
                    label: "Banner de la app",
                    help: "Banner que se mostrará en la home de la app.",
                  }}
                  size={12}
                  value={valueForPath("banner")}
                  onChange={(e) => {
                    setValueForPath("banner", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3} style={{ display: "flex", flexDirection: "row" }}>
                <Grid container spacing={2}>
                  <FormField
                    id={`field_icon`}
                    error={erroredFields("icon")}
                    definition={{
                      key: "icon",
                      type: "image",
                      aspectRatio: 1,
                      borderRadius: 10,
                      width: 512,
                      height: 512,
                      label: "Icono de la app",
                      help: "Icono de la app.",
                    }}
                    size={3}
                    value={valueForPath("icon")}
                    onChange={(e) => {
                      setValueForPath("icon", e.target.value);
                    }}
                  />
                  <FormField
                    id={`field_logo`}
                    error={erroredFields("logo")}
                    definition={{
                      key: "logo",
                      type: "image",
                      aspectRatio: 3.5,
                      borderRadius: 10,
                      width: 800,
                      height: 256,
                      label: "Logo de la app",
                      help: "Logo que se mostrará en la app.",
                    }}
                    size={9}
                    value={valueForPath("logo")}
                    onChange={(e) => {
                      setValueForPath("logo", e.target.value);
                    }}
                  />
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Módulos
              </Typography>
              <Box mt={3}>
                <DraggableModules
                  onChange={(e) => {
                    setValueForPath("newModules", e.target.value);
                    alert(JSON.stringify(e.target.value))
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Control de acceso
              </Typography>
              <Box mt={3}>
                <FormField
                  id={`field_privateApp`}
                  error={erroredFields("accessOptions.private")}
                  definition={{
                    key: "privateApp",
                    type: "switch",
                    label: "App privada",
                    help:
                      "Decide si quieres que se requiera usuario/contraseña para acceder",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.private")}
                  onChange={(e) => {
                    setValueForPath("accessOptions.private", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_registerEnabled`}
                  error={erroredFields("accessOptions.registerable")}
                  definition={{
                    key: "registerEnabled",
                    type: "switch",
                    label: "Registro habilitado",
                    help:
                      "Decide si quieres que los usuarios se puedan registrar",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.registerable")}
                  onChange={(e) => {
                    setValueForPath(
                      "accessOptions.registerable",
                      e.target.value
                    );
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_loginEnabled`}
                  error={erroredFields("accessOptions.loginable")}
                  definition={{
                    key: "loginEnabled",
                    type: "switch",
                    label: "Login habilitado",
                    help:
                      "Decide si quieres que se los usuarios puedan usar email/contraseña para acceder",
                  }}
                  size={12}
                  value={valueForPath("accessOptions.loginable")}
                  onChange={(e) => {
                    setValueForPath("accessOptions.loginable", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box m={2} style={{textAlign: 'right'}}>
          <Button color="primary" size='large' onClick={handleSave} variant="contained">
            Guardar
          </Button>
        </Box>
      </Grid>
      <Grid item md={4}>
        <Box m={2}>
          <Paper className="AppPreview">
            <Box p={3}>
              <div className="AppMock">
                <div className="Header">
                  <span className="Arrow">
                    <ChevronLeft
                      className="Icon"
                      style={
                        valueForPath("color")
                          ? { color: valueForPath("color") }
                          : null
                      }
                    />
                  </span>
                  <span
                    className="Title"
                    style={
                      valueForPath("color")
                        ? { color: valueForPath("color") }
                        : null
                    }
                  >
                    {valueForPath("name") || "Nombre de la app"}
                  </span>
                </div>
                <div
                  className="Banner"
                  style={{
                    paddingTop: `${(1 / 1.9) * 100}%`,
                    position: "relative",
                    borderRadius: 10,
                    backgroundImage: valueForPath("banner")
                      ? `url(${scaledImageSource(
                          valueForPath("banner"),
                          1200,
                          640,
                          false
                        )})`
                      : null,
                  }}
                ></div>
                <div className="Modules">
                  {valueForPath("newModules") &&
                    valueForPath("newModules").map((nm) => (
                      <div className="Module">
                        <AccountBalance
                          className="Icon"
                          style={
                            valueForPath("color")
                              ? { color: valueForPath("color") }
                              : null
                          }
                        />
                        <span className="Label">{nm.title}</span>
                      </div>
                    ))}
                </div>
                <div className="Footer">
                  <Button
                    variant="contained"
                    color={"secondary"}
                    style={
                      valueForPath("color")
                        ? { backgroundColor: valueForPath("color") }
                        : null
                    }
                  >
                    Button
                  </Button>
                </div>
              </div>
              <Box p={4}>
                <div>
                  <pre>{/*JSON.stringify(newObject, null, 2)*/}</pre>
                </div>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );

  let navigate = useNavigate();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: "Crear una nueva app" },
        ]}
      />
      <PageScreen className="Wizard">
        <PageHeader title="Crear una nueva app" renderActions={() => null} />
        <Form
          renderFields={renderFields}
          createAction={(payload) =>
            props.actions.apps.create({ ...payload, terms: termsForApp(payload.langPattern?.available||[]) })
          }
          patchAction={(payload) =>
            props.actions.apps.patch(payload)
          }
          handleSuccess={action => {
            navigate(`../`)
          }}
        />
      </PageScreen>
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    apps: state.data.apps,
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(Wizard);
