import React, { useState, useEffect } from 'react';

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import { ReactSortable } from "react-sortablejs";

export default props => {

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setItems(props.items);
  }, [props.items])

  const handleChangePage = (event, newPage) => {
    props.loadPage(newPage, 10).then(r => setPage(newPage));
  };

  const handleReorder = ({ item, oldIndex, newIndex }) => {
    //alert(`Dragged id ${JSON.stringify(item.getAttribute("data-id"))} from index ${oldIndex} to index ${newIndex}`);
    if (props.handleReorder) {
      props.handleReorder(item.getAttribute("data-id"), oldIndex, newIndex);
    }
  };

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} className="DataTable">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox inputProps={{ "aria-label": "select all items" }} />
              </TableCell>
              {props.columnDefs.map((cd) => (
                <TableCell align={cd.align} style={cd.style}>
                  {props.renderHeader(cd.ref)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <ReactSortable disabled={!props.handleReorder} tag={TableBody} list={items.map(i => ({...i, id: i._id}))} setList={setItems} onSort={handleReorder}>
            {items.map((item) => (
              <TableRow key={item._id}>
                <TableCell padding="checkbox">
                  <Checkbox inputProps={{ "aria-label": "select all items" }} />
                </TableCell>
                {props.columnDefs.map((cd, key) => (
                  <TableCell
                    component={key == 0 ? "th" : "td"}
                    align={cd.align}
                    style={cd.style}
                  >
                    {props.renderCell(cd.ref, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </ReactSortable>

        </Table>
      </TableContainer>
      {props.total ?
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={props.total}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
        />
      : null }
    </Grid>
  );
}