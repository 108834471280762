import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";

import Moment from "react-moment";
import nativeMoment from "moment-timezone";
import "moment/locale/es";


export default props => {


  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'sendAfter', sort: 'desc' }],
        },
      }}
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar notificaciones?",
          description: `Esta acción eliminará por completo las
          notificaciones, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => { });
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'title',
          header: 'Título',
          multilang: true
        },
        {
          key: 'immediate',
          header: "Inmediata"
        },
        {
          key: 'sendAfter',
          header: 'Fecha',
          value: (item) => nativeMoment(nativeMoment.tz(item.sendAfter, item.timezone || 'Europe/Madrid').format('YYYY-MM-DD HH:mm:ss') + ` (${item.timezone || 'Europe/Madrid'})`).toDate(),
          importValue: (item) => nativeMoment.tz(nativeMoment(item.sendAfter).format('YYYY-MM-DD HH:mm:ss'), item.timezone || 'Europe/Madrid')
        },
        {
          key: 'timezone',
          header: 'Zona horaria',
        },
      ]}
      importFormatter={(items) => {
        return items;
      }}
      humanize={(item) => `${item.title}`}
      importHandler={false}
      // IMPORT EXPORT END
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "title",
          headerName: "Título",
          valueGetter: (params) => params.value[props.defaultLang],
          flex: 3
        },
        {
          field: "everyone",
          headerName: "Destino",
          valueGetter: (params) => {
            if (params.value) return "Todos";
            else return "Algunos";
          },
          flex: 1
        },
        {
          field: "immediate",
          headerName: "Inmediata",
          valueGetter: (params) => {
            const sendAfter = params.getValue(params.id, "sendAfter");
            return sendAfter ? "No" : "Si";
          },
          flex: 1
        },
        {
          field: "sendAfter",
          headerName: "Fecha",
          valueGetter: (params) => {
            const sendAfter = params.value;
            const createdAt = params.getValue(params.id, "createdAt");
            return sendAfter || createdAt;
          },
          sortComparator: (v1, v2, param1, param2) => {
            return nativeMoment(param1.value).diff(nativeMoment(param2.value), 'minutes')
          },
          renderCell: (params) => {
            const timezone = params.getValue(params.id, "timezone");
            return <Fragment>{nativeMoment.tz(params.value, timezone || 'Europe/Madrid').format('L HH:mm') + ` (${timezone || 'Europe/Madrid'})`}</Fragment>;
          },
          flex: 2
        },
        {
          field: "sent",
          headerName: "Enviada",
          valueGetter: (params) => {
            return params.value ? "Si" : "No";
          },
          flex: 1
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar notificación?",
                      description: `Esta acción eliminará por completo la
                      notificación, así como todo su contenido asociado.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => { });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "Añade tu primera notificación",
        body:
          "Añade notificaciones a tu app y comunícate así con tus usuarios.",
        cta: "+ Crear nueva notificación",
        action: props.handleNew,
      }}
    />
  );
}