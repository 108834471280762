import React, { Fragment, useState, useEffect } from 'react';

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import RightModal from "../../components/RightModal/v2";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminsActionCreators from "../../data/admins/actions";

import { Link, useParams } from "react-router-dom";

import * as DataSource from './DataSource';
import List from './List';
import FeathersHelper from '../../helpers/feathers';
import { Construction } from '@mui/icons-material';
import axios from 'axios';

const Admins = (props) => {

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({ });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const downloadTicket = async (id, isDownload = true) => {
    // const pdf = await FeathersHelper.get('tickets', id);
    // const pdfBlob = new Blob([pdf.data], { type: 'application/pdf' })
    // const pdfUrl = window.URL.createObjectURL(pdfBlob)

    // if (isDownload) {
    //   console.log(pdf);
    //   //const buffer = Buffer.from(pdf.data);
    //   const link = document.createElement('a')
    //   link.download = 'my-shiny-new-pdf.pdf'
    //   link.href = pdfUrl
    //   link.click()
    //   link.remove()
    // } else {
    //   // printing is easy, thanks to @crabbly :D
    //   //printJs(pdfUrl)
    // }

    axios({
      url: `${process.env.REACT_APP_API_URL}/tickets/${id}`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("ubelong_admin_jwt")
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${id}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  const downloadTickets = async (selectedRows) => {

    axios({
      url: `${process.env.REACT_APP_API_URL}/tickets?appId=${props.selectedApp.slug}&$sort[lastName]=1&$sort[firstName]=1`, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("ubelong_admin_jwt")
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${props.selectedApp.slug}-tickets.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect(() => {
    props.actions.admins.findItems({ query: DataSource.query });
  }, []);

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Administradores" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Administradores"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo administrador</HeaderButton>
            </Fragment>
          )}
        />
        <List
          loading={props.admins.loading}
          items={props.admins.items}
          total={props.admins.total}
          handleNew={handleNew}
          handleCreate={props.actions.admins.create}
          handleEdit={handleEdit}
          handlePatch={(item) => props.actions.admins.patch(item,item)}
          handleRemove={props.actions.admins.remove}
          handleRemoveMany={props.actions.admins.removeMany}
          downloadTicket={downloadTicket}
          downloadTickets={downloadTickets}
          defaultLang={props.defaultLang}
          langs={props.selectedApp.langPattern.available}
          selectedApp={props.selectedApp}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear administrador"
        editTitle="Editar administrador"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.admins.create}
        patchAction={props.actions.admins.patch}
        manifest={DataSource.modalManifest}
        actions={props.actions.admins}
      />
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    admins: state.data.admins,
  }),
  (dispatch) => ({
    actions: {
      admins: bindActionCreators(adminsActionCreators, dispatch),
    },
  })
)(Admins);