import { combineReducers } from "redux";
import appsReducer from "./apps/reducer";
import modulesReducer from "./modules/reducer";
import usersReducer from "./users/reducer";
import invitecodesReducer from "./invitecodes/reducer";
import audiencesReducer from "./audiences/reducer";
import virtualRoomsReducer from "./virtualRooms/reducer";
import notificationsReducer from "./notifications/reducer";
import questionsReducer from "./questions/reducer";
import announcementsReducer from "./announcements/reducer";
import alertsReducer from "./alerts/reducer";
import postsReducer from "./posts/reducer";
import interactionsReducer from "./interactions/reducer";
import meetingsReducer from "./meetings/reducer";
import checkpointsReducer from "./checkpoints/reducer";
import licensesReducer from "./licenses/reducer";
import adminsReducer from "./admins/reducer";

export default combineReducers({
  apps: appsReducer,
  modules: modulesReducer,
  invitecodes: invitecodesReducer,
  users: usersReducer,
  audiences: audiencesReducer,
  virtualRooms: virtualRoomsReducer,
  notifications: notificationsReducer,
  questions: questionsReducer,
  announcements: announcementsReducer,
  alerts: alertsReducer,
  posts: postsReducer,
  interactions: interactionsReducer,
  meetings: meetingsReducer,
  checkpoints: checkpointsReducer,
  licenses: licensesReducer,
  admins: adminsReducer
});
