import React, { Fragment, useState, useEffect } from "react";



import { Grid, Box, Button } from "@mui/material/";
import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import Form from "../../components/Form";
import FormField from "../../components/FormField";
import Field from '../../components/RightModal/components/Field';

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checkpointsActionCreators from "../../data/checkpoints/actions";
import * as appsActionCreators from "../../data/apps/actions";
import * as appActionCreators from "../../services/app/actions";

import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TextField from '@mui/material/TextField';

import * as DataSource from './DataSource';
import List from './List';
import FeathersHelper from "../../helpers/feathers";


const Fastpass = (props) => {


  useEffect(() => {
    props.actions.checkpoints.findItems({ query: DataSource.query });
  }, []);

  const updated = (elem) => {
    if (elem.appId == props.selectedApp.slug) props.actions.checkpoints.updateOne(elem);
  }

  useEffect(() => {

    // Subscribe to real-time events
    if (props.selectedApp.slug) FeathersHelper.app.service('checkpoints').on('patched', updated);

    return function cleanup() {
      FeathersHelper.app.service('checkpoints').removeListener('patched', updated);
    };
  }, [props.selectedApp.slug]);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleNew = () => {
    setCurrentItem({ permission: { everyone: true, audiences: [] } });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const { members, ...rest } = item;
    setCurrentItem(rest);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };


  const confirm = useConfirm();

  const renderFields = (
    newObject,
    valueForPath,
    setValueForPath,
    erroredFields,
    handleSave,
    currentLang,
    setCurrentLang
  ) => (
    <React.Fragment>
      <Box>
        <Field
          id={`field_infoTitle`}
          definition={{
            key: "plugins.fastPass.active",
            multilang: false,
            type: "switch",
            label: "Activar fastpass",
            help: "Activar la funcionalidad de fastpass",
          }}
          size={12}
          basePath={[]}
          currentLang={currentLang}
          erroredFields={erroredFields}
          valueForPath={valueForPath}
          setValueForPath={setValueForPath}
        />
      </Box>
      <Box mt={3}>
        <Field
          id={`field_eventDate`}
          definition={{
            key: "plugins.fastPass.eventDate",
            multilang: false,
            type: "datetime",
            timezoneKey: "plugins.fastPass.timezone",
            label: "Inicio del evento",
            help: "Fecha y hora en la que empieza el evento",
          }}
          size={12}
          basePath={[]}
          currentLang={currentLang}
          erroredFields={erroredFields}
          valueForPath={valueForPath}
          setValueForPath={setValueForPath}
        />
      </Box>
      <Box mt={3}>
        <Field
          id={`field_pdfTemplate`}
          definition={{
            key: "plugins.fastPass.pdfTemplate",
            multilang: false,
            type: "pdf",
            aspectRatio: 1.9,
            borderRadius: 10,
            label: "Plantilla PDF",
            help: "Plantilla sobre la cual se generarán las acreditaciones",
          }}
          size={12}
          basePath={[]}
          currentLang={currentLang}
          erroredFields={erroredFields}
          valueForPath={valueForPath}
          setValueForPath={setValueForPath}
        />
      </Box>
      {/* <Box mt={3}>
        <FormField
          id={`field_passbookBackgroundColor`}
          error={erroredFields("color")}
          definition={{
            key: "color",
            type: "color",
            label: "Color de fondo PKPass",
            codeLabel: "Código hex",
            help: "Color de fondo del PKPass.",
          }}
          size={12}
          value={valueForPath("plugins.fastPass.passbook.backgroundColor")}
          onChange={(e) => {
            setValueForPath("plugins.fastPass.passbook.backgroundColor", e.target.value);
          }}
        />
      </Box> */}
      <Box mt={3}>
        <Grid container>
          <Grid xs={4}>
            <Field
              id={`field_pdfTemplate`}
              definition={{
                key: "plugins.fastPass.passbook.icon",
                multilang: false,
                type: "image",
                width: 87,
                height: 87,
                aspectRatio: 1,
                borderRadius: 10,
                label: "Icono PKPass",
                help: "Icono para el PKPass (87x87 px)",
              }}
              size={12}
              basePath={[]}
              currentLang={currentLang}
              erroredFields={erroredFields}
              valueForPath={valueForPath}
              setValueForPath={setValueForPath}
            />
          </Grid>
          <Grid xs={8}>
            <Box ml={2}>
              <Field
                id={`field_pdfTemplate`}
                definition={{
                  key: "plugins.fastPass.passbook.logo",
                  multilang: false,
                  type: "image",
                  width: 120,
                  height: 500,
                  aspectRatio: 4.16,
                  borderRadius: 10,
                  label: "Logo PKPass",
                  help: "Logo para el PKPass (120x500 px)",
                }}
                size={12}
                basePath={[]}
                currentLang={currentLang}
                erroredFields={erroredFields}
                valueForPath={valueForPath}
                setValueForPath={setValueForPath}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Field
          id={`field_pdfTemplate`}
          definition={{
            key: "plugins.fastPass.passbook.strip",
            multilang: false,
            type: "image",
            width: 930,
            height: 252,
            aspectRatio: 3.69,
            borderRadius: 10,
            label: "Strip PKPass",
            help: "Strip para el PKPass (930x252 px)",
          }}
          size={12}
          basePath={[]}
          currentLang={currentLang}
          erroredFields={erroredFields}
          valueForPath={valueForPath}
          setValueForPath={setValueForPath}
        />
      </Box>
      <Box mt={3}>
        <Field
          id={`field_zebraTemplate`}
          definition={{
            key: "plugins.fastPass.zebraTemplate",
            multilang: false,
            type: "select",
            options: [
              { key: '4inx12in_direct', label: '4" x 12" Directa' },
              { key: '8cmx4cm_transfer', label: '8cm x 4cm Transfer' },
              { key: '95mmx280mm_transfer', label: '9.5cm x 28cm Transfer'}
            ],
            label: "Plantilla Zebra",
            help: "Tipo de etiqueta Zebra",
          }}
          size={12}
          basePath={[]}
          currentLang={currentLang}
          erroredFields={erroredFields}
          valueForPath={valueForPath}
          setValueForPath={setValueForPath}
        />
      </Box>
      <Box mt={3}>
        {JSON.stringify(valueForPath('plugins.fastPass.zpl'))}
        <img style={{ borderColor: '#EEEEEF', borderWidth: 1, borderStyle: 'solid', maxWidth: '100%', borderRadius: 10 }} src={`http://api.labelary.com/v1/printers/8dpmm/labels/${valueForPath('plugins.fastPass.zplWidth') || 2}x${valueForPath('plugins.fastPass.zplHeight') || 2}/0/${(valueForPath('plugins.fastPass.zpl')||"").replace('\\&','')}`} />
      </Box>
      <Box m={2} style={{ textAlign: "right" }}>
        <Button
          color="primary"
          size="large"
          onClick={handleSave}
          variant="contained"
        >
          Guardar
        </Button>
      </Box>
    </React.Fragment>

  );

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Checkpoints" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Checkpoints"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo checkpoint</HeaderButton>
            </Fragment>
          )}
        />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <List
              loading={props.checkpoints.loading}
              items={props.checkpoints.items}
              total={props.checkpoints.total}
              handleNew={handleNew}
              handleCreate={props.actions.checkpoints.create}
              handleEdit={handleEdit}
              handlePatch={(item) => props.actions.checkpoints.patch(item, item)}
              handleRemove={props.actions.checkpoints.remove}
              handleRemoveMany={props.actions.checkpoints.removeMany}
              defaultLang={props.defaultLang}
              langs={props.selectedApp.langPattern.available}
              selectedApp={props.selectedApp}
            />
          </Grid>
          <Grid item xs={5}>
            <Box style={{ marginTop: 52 }}>
              <div className="UserRanking">
                <div className="Header">
                  <h2>Ajustes</h2>
                </div>
                <div className="Body">
                  <Box p={4}>
                    {props.selectedApp._id ?
                      <Form
                        defaultValue={props.selectedApp}
                        renderFields={renderFields}
                        createAction={(payload) => props.actions.apps.create(payload)}
                        patchAction={(payload) => props.actions.apps.patch(payload).then(() => props.actions.app.setSnack({ open: true, severity: 'success', message: "Ajustes guardados" }))}
                        handleClose={() => { }}
                        handleSuccess={(action) => {

                        }}
                      />
                      : null}
                  </Box>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>

      </PageScreen>
      <RightModal
        newTitle="Crear checkpoint"
        editTitle="Editar checkpoint"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.checkpoints.create}
        patchAction={props.actions.checkpoints.patch}
        manifest={DataSource.modalManifest}
        actions={props.actions.checkpoints}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    checkpoints: state.data.checkpoints,
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
      apps: bindActionCreators(appsActionCreators, dispatch),
      checkpoints: bindActionCreators(checkpointsActionCreators, dispatch),
    },
  })
)(Fastpass);
