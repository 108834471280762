import React, { useState, useRef, useCallback, useMemo } from 'react';

import { Grid, TableContainer, Paper, Button, IconButton, Box, LinearProgress, Typography, TextField } from "@mui/material";
import {
  SaveAlt as SaveAltIcon,
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Clear as ClearIcon
} from "@mui/icons-material"

// import {
//   DataGrid,
//   GridToolbar,
//   GridFilterPanel,
//   esES,
// } from '@material-ui/data-grid';


import {
  DataGrid,
  GridToolbar,
  GridFilterPanel,
  esES,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  gridClasses,
  gridVisibleSortedRowIdsSelector,
  useGridApiRef,
  useGridApiContext
} from '@mui/x-data-grid';

import { styled } from '@mui/material/styles';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { AsyncSemaphore } from '../../../../helpers/asyncSemaphore';

import _ from 'lodash'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-main': {
    backgroundColor: '#FFF',
    borderRadius: 20,
    marginTop: 15
  },
  '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
    borderBottom: 'none'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    paddingLeft: 0
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: ".6875rem",
    color: "#686e71",
    fontWeight: "500",
    textTransform: "uppercase"
  },
}));

const StyledFilterPanel = styled(GridFilterPanel)(({ theme }) => ({
  border: '1px solid red',
  '& .MuiDataGrid-panelWrapper': {
    border: '1px solid red'
  }
}));

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const UploadButton = props => (
  <label htmlFor="upload-photo">
    <input
      style={{ display: 'none' }}
      id="upload-photo"
      name="upload-photo"
      type="file"
      onChange={props.onChange}
    />

    <Button {...props} component="span">
      {props.children}
    </Button>
  </label>
);

const BelongDataGrid = props => {

  const [pageSize, setPageSize] = useState(20);

  const [importable, setImportable] = useState([]);
  const [importing, setImporting] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [successful, setSuccessful] = useState([]);
  const [failed, setFailed] = useState([]);

  const CustomToolbar = () => {
    const apiContext = useGridApiContext();

    const visibleRows = Array.from(apiContext.current.getVisibleRowModels(), ([key, value]) => key);
    const selectedRows = Array.from(apiContext.current.getSelectedRows(), ([key, value]) => key);

    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer} sx={{
          pb: 0,
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}>
          <div>
            <GridToolbarColumnsButton variant="contained" color="secondary" style={{ marginRight: 5 }} />
            <GridToolbarFilterButton variant="contained" componentsProps={{ button: { color: 'secondary' } }} style={{ marginRight: 5 }}  />
            <Button size="small" variant="contained" color="secondary" onClick={() => exportToExcel(visibleRows, selectedRows)} startIcon={<SaveAltIcon />} style={{ marginRight: 5 }}>
              {selectedRows && selectedRows.length > 0 ? 'Exportar seleccionados' : 'Exportar a Excel'}
            </Button>
            {props.importHandler ?
              (
                importable.length > 0 ?
                <React.Fragment>
                  <Button size="small" variant="contained" color="success" onClick={confirmImport} startIcon={<CheckIcon />} style={{ marginRight: 5 }}>
                    Confirmar importación
                  </Button>
                  <Button size="small" variant="contained" color="error" onClick={() => {
                    setImportable([]);
                  }} startIcon={<CloseIcon />} style={{ marginRight: 5 }}>
                    Cancelar importación
                  </Button>
                </React.Fragment>
                :
                <UploadButton size="small" variant="contained" color="secondary" onChange={(e) => handleImport(e.target.files)} startIcon={<UploadFileIcon />} style={{ marginRight: 5 }}>
                  Importar desde Excel
                </UploadButton>
              )
            : null}
            {props.extraActions ? props.extraActions(selectedRows) : null}
            {selectedRows && selectedRows.length > 0 ?
              <React.Fragment>
                {props.removeMany ?
                  <Button size="small" variant="contained" color="secondary" onClick={() => props.removeMany(selectedRows)} startIcon={<DeleteIcon />}>
                    Eliminar seleccionados
                  </Button>
                : null}
              </React.Fragment>
            : null }
          </div>
          {/*
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            className="NoMargins"
            sx={{
              margin: "0 !important",
              width: {
                xs: 1,
                sm: 'auto',
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '.MuiFormControl-root': {
                margin: "0 !important",
              },
              '& .MuiInput-root': {
                margin: "0 !important",
              },
              '& .MuiSvgIcon-root': {
                mr: 0.5,
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider',
              },
            }}
          />
          */}
        </GridToolbarContainer>
    );
  };

  const exportToExcel = (visibleRows, selectedRows) => {
    const ids = selectedRows && selectedRows.length > 0 ? selectedRows : visibleRows;
    const items = props.items.filter(i => ids.includes(i._id))

    // OLD WAY WITH COLUMNDEFS
    // let excelData = [ props.columnDefs.filter(cd => !cd.skipExcel).map(cd => cd.headerName ) ];
    // excelData = [ ...excelData, ...(
    //   items.map( u => {
    //     return props.columnDefs.filter(cd => !cd.skipExcel).map(cd => cd.excelValue ? cd.excelValue(u) : u[cd.field] );
    //   })
    // )];

    // NEW WAY WITH excelColumns
    let excelData = [ props.excelColumns.reduce( (acc, cur) => {
      if (cur.multilang) return [ ...acc, ...props.langs.map( l => `${cur.header} ${l}` )];
      else return [ ...acc, cur.header];
    }, [] ) ];

    excelData = [ ...excelData, ...(
      items.map( u => {
        return props.excelColumns.reduce((acc, cur) => {
          const value = cur.value ? cur.value(u) : _.get(u, cur.key)
          console.log("EPA", u[cur.key])
          if (cur.multilang) return [...acc, ...props.langs.map( l => value[l] )]
          else return [...acc, value]
        }, []);
      })
    )];

    const colWidths = [];

    excelData.map( row => {
      row.map( (cell, i) => {
        let length = 5;
        switch(typeof cell) {
          case 'string':
            length = cell.length;
            break;
          case 'undefined':
            length = 0;
            break;
          case 'object':
            length = 13;
            break;
        }
        length = length + 2;
        if (colWidths[i]) {
          if (colWidths[i] < length) colWidths[i] = length
        }
        else colWidths.push(length)
      })
    })

    //Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = XLSX.utils.aoa_to_sheet(excelData, { dateNF:'dd/mm/yy hh:mm', cellDates: true });
    workSheet["!cols"] = colWidths.map(cw => ({ width: cw }));
    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array", cellDates: true });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(fileData, 'report.xlsx');
  };

  const handleImport = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array', cellDates: true });
        var result = [];
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1});
        if (roa.length) result = roa;
        //setImportable(result);
        //alert(JSON.stringify(result))
        //alert(JSON.stringify(headers))
        const headers = props.excelColumns.reduce((acc,cur) => {
          if (cur.multilang) {
            props.langs.map(l => {
              acc[`${cur.header} ${l}`] = { ...cur, lang: l};
            })
            return acc;
          }
          else return { ...acc, [cur.header]: cur }
        }, {});
        setImportable(props.importFormatter(result.slice(1).map((item, i) => {
          const newItem = {
            ...result[0].reduce((acc,h,j) => {
              const value = item[j]
              if (headers[h].multilang) {
                return { ...acc, [headers[h].key]: { ...(acc[headers[h].key]||{}), [headers[h].lang]: value }}
              } else {
                return { ...acc, [headers[h].key]: value}
              }
            }, {}),
            id: item._id||`new_${i}`
          };

          result[0].map(h => {
            const headerIndex = Object.keys(headers).indexOf(h);
            if (headers[h].importValue) {
              newItem[headers[h].key] = headers[h].importValue(newItem);
            }
          })

          return newItem;
        })));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const createOne = (item) => {
    let {id, ...noId} = item;
    return props.create(noId).then(r => {
      setSuccessful((cs) => [...cs, item]);
    }).catch(e => {
      console.log(e)
      setFailed((cs) => [...cs, { item, error: e }]);
    })
  }

  const patchOne = (item) => {
    let {id, ...noId} = item;
    return props.patch(noId).then(r => {
      setSuccessful((cs) => [...cs, item]);
    }).catch(e => {
      console.log(e)
      setFailed((cs) => [...cs, { item, error: e }]);
    })
  }

  const confirmImport = async () => {
    // BATCH
    setImporting(true);
    setRecordCount(importable.length);
    const semaphore = new AsyncSemaphore(1);
    for (var i = 0, len = importable.length; i < len; i++) {
      await semaphore.withLockRunAndForget(() => {
        if (importable[i]._id) return patchOne(importable[i]);
        else return createOne(importable[i]);
      })
    }
    await semaphore.awaitTerminate();
  };



  const memoedGrid = useMemo(() => {
    return (
      <StyledDataGrid
        autoHeight
        components={{
          Toolbar: CustomToolbar,
          FilterPanel: StyledFilterPanel
        }}
        classes={{
          main: ''
        }}
        rowHeight={70}
        checkboxSelection={importable.length == 0}
        rows={importable.length > 0 ? importable : props.items}
        columns={importable.length > 0 ? props.columnDefs.filter(cd => cd.field != 'actions') : props.columnDefs.filter(cd => cd.field != 'import')}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
        disableSelectionOnClick
        initialState={props.initialState}
      />
    );
  }, [props.items, importable, pageSize]);

  return (
    <React.Fragment>
      {importing ?
        <React.Fragment>
          <Box mt={2}>
            <Typography>Importando...</Typography>
          </Box>
          <Box mt={2}>
            <LinearProgressWithLabel
              value={
                successful.length > 0 || failed.length > 0
                  ? 100 *
                  (((successful.length + failed.length) * 1.0) /
                    recordCount)
                  : 0
              }
            />
          </Box>
          <Box mt={2}>
            <Typography color="success.main">{successful.length}/{recordCount} completados</Typography><Typography color="error">{failed.length} fallidos</Typography>
          </Box>
          {failed.length > 0 &&
            <Box mt={2}>
              <Paper>
                <ul style={{ margin: 0, padding: 10, listStyleType: 'none', fontFamily: 'monospace' }}>
                  {failed.map((f) => (
                    <li>{props.humanize(f.item)}: {f.error.message}</li>
                  ))}
                </ul>
              </Paper>
            </Box>
          }
          {recordCount - successful.length - failed.length == 0 &&
            <Box mt={2}>
              <Button variant="contained" onClick={() => {
                setImportable([]);
                setImporting(false);
                setRecordCount(0);
                setSuccessful([]);
                setFailed([]);
              }}>Volver</Button>
            </Box>
          }
        </React.Fragment>
      :
        <div className='DataTable'>
          {memoedGrid}
        </div>
      }
    </React.Fragment>
  );
}

export default BelongDataGrid;