import React, { useState, useEffect } from 'react';

import {
  Button,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import FeathersHelper from '../../../../../../helpers/feathers';

const useStyles = makeStyles((theme) => ({
  audienceList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10
  },
  audienceBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
    marginBottom: 5,
    border: '1px solid #B4B4B7',
    fontSize: 13,
    color: '#716B68',
    height: 25,
    borderRadius: 13,
    backgroundColor: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#B4B4B7'
    }
  },
  activeAudience: {
    borderColor: '#4B4B4B',
    backgroundColor: '#4B4B4B',
    color: '#FFFFFF'
  }
}));

export default props => {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.definition.options);
  }, []);

  const classes = useStyles();

  const handleToggle = (option) => {
    const value = props.value();
    if (value.includes(option._id)) {
      props.onChange({ target: { value: value.filter(v => v != option._id ) } })
    } else {
      props.onChange({ target: { value: [ ...value, option._id ] } })
    }
  }

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <FormControl
        variant="outlined"
        //className={classes.formControl}
        fullWidth
      >
        <div className={classes.audienceList}>
          {options.map((o) => (
            <span onClick={() => handleToggle(o)} className={`${classes.audienceBadge} ${props.value() && props.value().includes(o._id) ? classes.activeAudience : ''}`}>{o.label}</span>
          ))}
        </div>
      </FormControl>
      <FormHelperText
        className={`${props.classes.helperText} ${
          props.error ? props.classes.error : null
        }`}
      >
        {props.error || props.definition.help}
      </FormHelperText>
    </React.Fragment>
  );
};