import React, { useEffect, useState } from 'react';
import { useRoutes } from "react-router-dom";

import routes from './routes';

import {  } from "@mui/material";
import {  } from "@mui/icons-material";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "./data/apps/actions";

import 'react-quill/dist/quill.snow.css';
import './App.scss';


import AppLoader from "./components/AppLoader";



const App = props => {

  const [slugReady, setSlugReady] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
    if (props.apps.selected.slug !== slug) {
      if(props.app.feathersReady) {
        if (!["new","licenses", "admins","login"].includes(slug)) props.actions.apps.setSelectedFromSlug(slug);
      }
    }
    setSlugReady(true);
  }, [props.app.feathersReady, props.apps.selected.slug, props.actions.apps]);


  const isLoggedIn = props.app.feathersAuthenticated;

  const routing = useRoutes(routes(isLoggedIn, props.apps.selected));

  return (
    <div className="App">
        {props.app.feathersConnected && slugReady && props.app.feathersReady ? routing : <AppLoader /> }
      {props.apps.loading && <AppLoader />}
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    app: state.services.app,
    apps: state.data.apps,
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(App);