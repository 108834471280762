import React, {} from 'react';

import {
  InputLabel,
  FormHelperText,
  Switch
} from "@mui/material";


export default props => {
  return (
    <React.Fragment>
      <div>
        <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
          {props.definition.label}
        </InputLabel>
        <FormHelperText
          className={`${props.classes.helperText} ${
            props.error ? props.classes.error : null
          }`}
        >
          {props.error || props.definition.help}
        </FormHelperText>
      </div>
      <Switch
        checked={props.value()}
        onChange={props.onChange}
        color="primary"
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </React.Fragment>
  );
};