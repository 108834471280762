import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton, Grid, Button } from "@mui/material/";
import { RotateLeft, DeleteForever, Edit as EditIcon, Done as DoneIcon, Delete as DeleteIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";
import * as meetingsActionCreators from "../../data/meetings/actions";
import FeathersHelper from '../../helpers/feathers';

import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import { ScaledImage } from "../../components/ScaledImage";

const BASE_QUERY = {
  $limit: 10,
  $sort: { createdAt: -1 }
};

const Meetings = (props) => {

  let { moduleId } = useParams();

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [rankedUsers, setRankedUsers] = useState(null)
  const [allUsers, setAllUsers] = useState([])


  const MODAL_MANIFEST = [
    [
      {
        key: "startDate",
        timezoneKey: "timezone",
        type: "datetime",
        label: "Inicio",
        help: "Inicio del bloque de slots.",
      },
    ],
    [
      {
        key: "endDate",
        timezoneKey: "timezone",
        type: "datetime",
        label: "Fin",
        help: "Fin del bloque de slots.",
      },
    ],
    [
      {
        key: "interval",
        type: "string",
        label: "Intervalo",
        help: "Minutos entre slots.",
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [
  ];

  const loadItems = () => {
    props.actions.meetings.findItems({
      query: {
        ...BASE_QUERY,
      }
    });
  }

  useEffect(() => {
    loadItems();
  }, []);




  const handleNew = () => {
    setCurrentItem({
      timezone: 'Europe/Madrid'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const confirm = useConfirm();
  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Networking" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Networking"
          renderActions={() => (
            null
          )}
        />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <DataView
              items={props.data.meetings.items}
              total={props.data.meetings.total}
              loadPage={(page, perPage) => props.actions.meetings.findItems({ query: {
                ...BASE_QUERY,
                $limit: 10,
                $skip: (perPage * page)
              } })}
              columnDefs={[
                { ref: "date", align: "left" },
                { ref: "title", align: "left" },
                { ref: "participants", align: "left" },
                { ref: "actions", align: "left" },
              ]}
              renderHeader={(ref) => {
                switch (ref) {
                   case "date":
                    return "Fecha";
                  case "title":
                    return "Título";
                  case "participants":
                    return "Participantes";
                  case "actions":
                    return "Acciones";
                  default:
                    return null;
                }
              }}
              renderCell={(ref, item) => {
                switch (ref) {
                  case "date":
                    return nativeMoment(item[ref]).format('L HH:mm');
                  case "title":
                    return item[ref];
                  case "participants":
                    return item.participants.length;
                  case "actions":
                    return (
                      <Fragment>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="DeleteButton"
                          onClick={() => {
                            confirm({
                              title: "¿Eliminar reunión?",
                              description: `Esta acción eliminará por completo la reunión.`,
                              confirmationText: "Eliminar",
                              confirmationButtonProps: { variant: "contained" },
                              cancellationText: "Cancelar",
                              cancellationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                props.actions.meetings.remove(item);
                              })
                              .catch(() => {});
                          }}
                        >
                          <DeleteForever fontSize="small" />
                        </IconButton>
                      </Fragment>
                    );
                  default:
                    return null;
                }
              }}
              empty={{
                title: "Aun no hay reuniones",
                body:
                  "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
                cta: "+ Crear nueva reunión",
                action: handleNew,
              }}
            />
          </Grid>
          <Grid item xs="5">
            {props.selectedApp.plugins && props.selectedApp.plugins.meetings ?
              <div className="UserRanking">
                <div className="Header">
                  <h2>Franjas de reunión</h2>
                  <Button color="primary" onClick={() => handleNew()}>Nueva</Button>
                </div>
                <ol>
                  {(props.selectedApp.plugins.meetings.slots||[]).map((slot, index) => (
                    <li style={{ justifyContent: 'space-between' }}>
                      <div>
                        <span>{nativeMoment(slot.startDate).format('L HH:mm')} {"->"} {nativeMoment(slot.endDate).format('L HH:mm')}</span>
                        <span>, cada {slot.interval} minutos</span>
                      </div>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        className="DeleteButton"
                        onClick={() => {
                          confirm({
                            title: "¿Eliminar franja?",
                            description: `Esta acción eliminará por completo la
                            franja.
                            Esta acción es permenente y no tiene marcha atrás.`,
                            confirmationText: "Eliminar",
                            confirmationButtonProps: { variant: "contained" },
                            cancellationText: "Cancelar",
                            cancellationButtonProps: { variant: "contained" },
                          })
                            .then(() => {
                              props.actions.apps.removeSlot(props.selectedApp.slug, slot._id);
                            })
                            .catch(() => {});
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </li>
                  ))}
                </ol>
              </div>
            : null}
          </Grid>
        </Grid>
      </PageScreen>
      <RightModal
        newTitle="Crear franjas"
        editTitle="Editar franjas"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        createAction={(payload) =>
          props.actions.apps.createSlot(props.selectedApp.slug, payload)
        }
        patchAction={(currentItem, payload) =>
          props.actions.meetings.patchSlot(currentItem, payload)
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    data: {
      meetings: state.data.meetings
    },
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      meetings: bindActionCreators(meetingsActionCreators, dispatch),
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(Meetings);
