import React, { useEffect, useState, useCallback } from 'react';

import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
  Button,
  Switch
} from "@mui/material/";

import { makeStyles } from "@mui/styles";

import { useConfirm } from "material-ui-confirm";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../../data/modules/actions";
import * as questionsActionCreators from "../../../data/questions/actions";


import FeathersHelper from '../../../helpers/feathers';

import { useParams } from "react-router-dom";

import nativeMoment from "moment-timezone";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const PAGE_STEP = 10;

const QuestionReport = props => {

  //const [showTotal, setShowTotal] = useState(PAGE_STEP);
  const [fullModule, setFullModule] = useState({});

  const classes = useStyles();

  let { moduleId } = useParams();

  let baseQuery;

  if (props.master) {
    baseQuery = { moduleId };
  } else {
    baseQuery = { moduleId, tailored: true };
  }

  const initialLoad = () => {
    props.actions.questions.findItems({ query: { ...baseQuery, $limit: PAGE_STEP } });
  }

  const loadMore = () => {
    if (props.data.questions.items.length > 0) {
      const last = props.data.questions.items.slice(-1)[0];
      props.actions.questions.appendItems({ query: { ...baseQuery, _id: { $lt: last._id }, $limit: PAGE_STEP } });
    }
    //setShowTotal(st => st + PAGE_STEP);
  }

  const loadNewer = (newElement) => {
    // const first = props.data.questions.items[0];
    // alert(JSON.stringify(props.data.questions));
    //props.actions.questions.prependItems({ query: { ...baseQuery, _id: { $gt: first._id } } });
    if (props.master && newElement.moduleId == moduleId) props.actions.questions.prepend([newElement]);
  };

  const updated = (elem) => {

    if (props.master) {
      if (elem.moduleId == moduleId) props.actions.questions.add(elem);
    } else {
      if (elem.tailored) {
        if (elem.moduleId == moduleId) props.actions.questions.add(elem);
      } else {
        if (elem.moduleId == moduleId) props.actions.questions.destroy(elem);
      }
    }
  }

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
    FeathersHelper.get("modules",moduleId).then(r => {
      setFullModule(r);
    }).catch(e => {

    });
    initialLoad();

    // Subscribe to real-time events
    FeathersHelper.app.service('questions').on('created', loadNewer);
    FeathersHelper.app.service('questions').on('updated', updated);
    FeathersHelper.app.service('questions').on('patched', updated);

    return function cleanup() {
      FeathersHelper.app.service('questions').removeListener('created', loadNewer);
      FeathersHelper.app.service('questions').removeListener('updated', updated);
      FeathersHelper.app.service('questions').removeListener('patched', updated);
    };
  }, []);

  const confirm = useConfirm();

  return (
    <Container maxWidth="false">
      <h3>{props.data.questions.items.length}/{props.data.questions.total}</h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Pregunta</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell align="right">Usuario</TableCell>
              <TableCell align="right">Hora</TableCell>
              {props.master && <TableCell align="center">Mostrar</TableCell>}
              {props.master && <TableCell align="right">Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.questions.items.map((q) => (
              <TableRow key={q._id}>
                <TableCell component="th" scope="row">
                  {q.content}
                </TableCell>
                <TableCell>
                  {fullModule.topics ? fullModule.topics.find(t => t._id == q.topicId)?.title?.[props.selectedApp.langPattern.default] : ''}
                </TableCell>
                <TableCell align="right">{q.user ? `${q.user.firstName} ${q.user.lastName}` : 'Anónimo'}</TableCell>
                <TableCell align="right">{nativeMoment(q.createdAt).format('L HH:mm')}</TableCell>
                {props.master &&
                  <React.Fragment>
                    <TableCell align="center">
                      <Switch
                        color="primary"
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={(event) =>
                          props.actions.questions.patch(q, {
                            tailored: event.target.checked,
                          })
                        }
                        checked={q.tailored}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#EE8888'}}
                        onClick={() => {
                          confirm({
                            title: "¿Eliminar pregunta?",
                            description: `Esta acción eliminará por completo la
                            pregunta.`,
                            confirmationText: "Eliminar",
                            confirmationButtonProps: { variant: "contained" },
                            cancellationText: "Cancelar",
                            cancellationButtonProps: { variant: "contained" },
                          })
                            .then(() => {
                              props.actions.questions.remove(q);
                            })
                            .catch(() => {});
                        }}
                      >Eliminar</Button>
                    </TableCell>
                  </React.Fragment>
                }
              </TableRow>
            ))}
          </TableBody>
          {props.data.questions.items.length < props.data.questions.total &&
            <TableFooter>
              <TableCell colSpan={props.master ? 5 : 3}>
                <Button onClick={() => loadMore()} color="primary">Cargar más</Button>
              </TableCell>
            </TableFooter>
          }
        </Table>
      </TableContainer>
    </Container>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    data: {
      questions: state.data.questions
    },
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(QuestionReport);