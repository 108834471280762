import React, { useState, useEffect } from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import FeathersHelper from '../../../../../../helpers/feathers';

export default props => {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    FeathersHelper.find("rooms", { query: { $limit: -1 } }).then( r => {
      setOptions(r);
    }).catch( e => {
      console.log(e);
    });
  }, []);

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <FormControl
        variant="outlined"
        //className={classes.formControl}
        fullWidth
      >
        <Select
          displayEmpty
          value={props.value()}
          onChange={props.onChange}
        >
          {options.map((o) => (
            <MenuItem value={o._id}>{o.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};