import React, { useCallback } from 'react';
import {
  Box,
  Paper
} from "@mui/material/";


import { useDropzone } from "react-dropzone";

import XLSX from 'xlsx';

export default props => {

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        console.log("Workbook", workbook);
        var result = [];
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        if (roa.length) result = roa;
        props.setImported(result);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Paper {...getRootProps()} style={{display: 'inline-block'}}>
      <Box p={2}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Suelta el archivo aqui...</p>
        ) : (
          <p>Arrastra aquí el xlsx relleno con los usuarios</p>
        )}
      </Box>
    </Paper>
  );
}