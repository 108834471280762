import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

// OWN ROOT COMPONENTS

// WRAPPERS AND CONTEXT CREATORS
import FeathersHelper from '../../helpers/feathers';

// REDUX IMPORTS AND ACTION CREATORS
import { connect } from "react-redux";

// OTHER UTILS
import { makeStyles } from "@mui/styles";
import { useParams } from 'react-router-dom';

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#EAEBF0',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  table: {
    marginTop: 30
  },
  titleCell: {
    paddingRight: 20,
    paddingLeft: 20,
    borderTop: '1px solid #000',
    maxWidth: '40vw'
  },
  otherCell: {
    borderTop: '1px solid #000',
  }
}));

const MAX_REFRESH = 2000;
const REFRESH_TRESHOLD = 5000;

const Component = (props) => {


  const [sizingIndex, setSizingIndex] = useState(0);
  const [projection, setProjection] = useState(null);

  const classes = useStyles(sizingIndex);
  const { moduleId } = useParams();

  const { themeColor } = props.data.selectedApp;
  const components = hexToRgb(themeColor);

  const locale = props.data.selectedApp.langPattern ? props.data.selectedApp.langPattern.default : '_';


  useEffect(() => {

    FeathersHelper.get('projections', moduleId)
      .then( async p => {
        setProjection(p);
      }).catch((e) => {
        alert(JSON.stringify(e))
      });
  }, [])



  return projection ? (
    <div className={classes.container}>
      <h2 style={{ marginTop: 90 }}>{projection.title[locale]}</h2>
      <table className={classes.table}>
        <thead>
          <tr>
            <th></th>
            <th>Opción</th>
            <th>Votos</th>
            <th>Porcentaje</th>
          </tr>
        </thead>
        <tbody>
        {projection.options.map( (o,i) => (
          <tr>
            <td className={classes.otherCell}>{i+1}</td>
            <td className={classes.titleCell}>{o.title[locale]}</td>
            <td className={classes.otherCell}>{o.count}</td>
            <td className={classes.otherCell}>{Math.round(o.percent * 100)}%</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  ) : null;
}

export default connect(
  (state) => ({
    data: {
      selectedApp: state.data.apps.selected,
    },
  }),
  null
)(Component);