import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        loading: false,
        items: action.items,
        total: action.total
      };
    case actionTypes.APPEND:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
        total: action.total + state.items.length,
      };
    case actionTypes.PREPEND:
      return {
        ...state,
        loading: false,
        items: [...action.items, ...state.items],
        total: action.items.length + state.total,
      };
    case actionTypes.ADD:
      let copy = [ ...state.items ];
      const exists = state.items.map(i => i._id).indexOf(action.item._id);
      let total = state.total;
      if (exists != -1) {
        copy[exists] = action.item;
      } else {
        copy = [ ...copy, action.item ].sort((a,b) => a.createdAt < b.createdAt ? 1 : -1);
        total += 1;
      }
      return {
        ...state,
        loading: false,
        items: copy,
        total,
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => item._id === action.item._id ? action.item : item)
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => item._id !== action.item._id),
        total: state.total-1
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
