
export const query = {

};

export const modalManifest = [
  [
    {
      key: "startDate",
      timezoneKey: "timezone",
      type: "datetime",
      label: "Inicio del acto",
      help: "Inicio del acto.",
    },
  ],
  [
    {
      key: "endDate",
      timezoneKey: "timezone",
      type: "datetime",
      label: "Fin del acto",
      help: "Fin del acto.",
    },
  ],
  [
    {
      key: "title",
      multilang: true,
      type: "string",
      label: "Título del acto",
      help: "Título del acto.",
    },
  ],
  [
    {
      key: "body",
      multilang: true,
      type: "text",
      label: "Descripción del acto",
      help: "Descripción del acto.",
    },
  ],
  [
    {
      key: "roomId",
      type: "roomPicker",
      label: "Sala",
      help: "Elige una sala virtual donde enlazar el acto.",
    },
  ],
  [
    {
      key: "optional",
      type: "switch",
      label: "Opcional",
      help: "Hacer el appointment opcional e inscribible"
    },
  ],
  [
    {
      key: "seats",
      type: "string",
      label: "Plazas",
      help: "Número de plazas para inscripción.",
    },
  ],
];