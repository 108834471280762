import React, { useState } from 'react';

import {
  InputLabel,
  FormHelperText,
  TextField,
  Button
} from "@mui/material";


export default props => {

  const { currentLang } = props;

  const onChange = (k,v) => {
    if (props.multilang) {
      const globalVal = [ ...props.value() ];
      globalVal[k][currentLang] = v;
      props.onChange({ target: { value: globalVal } })
    } else {
      const globalVal = [ ...props.value() ];
      globalVal[k] = v;
      props.onChange({ target: { value: globalVal } })
    }
  }

  const newValue = () => {
    if (props.multilang) {
      return {};
    } else {
      return '';
    }
  }

  return (
    <React.Fragment>
      <div>
        <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
          {props.definition.label}
        </InputLabel>
        <FormHelperText
          className={`${props.classes.helperText} ${
            props.error ? props.classes.error : null
          }`}
        >
          {props.error || props.definition.help}
        </FormHelperText>
      </div>


      {(props.value()||[]).map((v,k) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            fullWidth
            value={props.multilang ? v[currentLang]||'' : v}
            onChange={(e) => {
              const newVal = e.target.value;
              onChange(k, newVal);
              //props.onChange({ target: { value: globalVal } })
            }}
          />
          <Button onClick={() => props.onChange({target: { value: (props.value()||[]).filter( (v,sk) => sk != k ) }})}>x</Button>
        </div>
      ))}

      <Button onClick={() => props.onChange({target: { value: [ ...(props.value()||[]), newValue() ] }})}>Nuevo item</Button>

    </React.Fragment>
  );
};