import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton,
  Avatar
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LockClock as LockClockIcon,
  Mail as MailIcon,
  ShoppingBasket as ShoppingBasketIcon,
  Money as MoneyIcon,
  DeleteForever as DeleteForeverIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";

import Moment from "react-moment";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import _ from 'lodash'

export default props => {


  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {

      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'appInfo.name',
          header: 'Nombre'
        },
        {
          key: 'type',
          header: 'Licencia'
        },
        {
          key: 'estimateNumber',
          header: 'presupuesto'
        },
        {
          key: 'origin',
          header: 'Origen',
          value: (item) => (item.origin == 'SaaS') ? `${item.origin} (${_.get(item.salesMeta, "appForm")|| 'No form'})` : item.origin
        },
        {
          key: 'admin',
          header: 'Gestor',
          value: (item) => item.admin ? `${item.admin.firstName} ${item.admin.lastName}`: ''
        },
        {
          key: 'status',
          header: 'Estado'
        },
        {
          field: "createdAt",
          headerName: "Creado",
          value: (item) => nativeMoment(nativeMoment.tz(item.createdAt, 'Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')).toDate(),
          flex: 2
        },
      ]}
      importFormatter={(items) => {
        return items;
      }}
      humanize={(item) => `${item.name}`}
      importHandler={false}
      // IMPORT EXPORT END
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "appName",
          headerName: "Nombre",
          valueGetter: (params) => params.getValue(params.id, 'appInfo').name,
          flex: 2
        },
        {
          field: "type",
          headerName: "Licencia",
          renderCell: (params) => {
            switch (params.value) {
              case 'Demo':
                return <Chip color="warning" size="small" label={params.value} />;
              case 'Lite':
                return <Chip color="info" size="small"  label={params.value} />;
              case 'Plus':
                return <Chip color="success" size="small" label={params.value} />;
              case 'Enterprise':
                return <Chip color="primary" size="small" label={params.value} />;
            }
          },
          flex: 1
        },
        {
          field: "estimateNumber",
          headerName: "Presupuesto",
          flex: 1
        },
        {
          field: "origin",
          headerName: "Origen",
          flex: 1,
          valueGetter: (params) => {
            const appForm = params.getValue(params.id, "salesMeta")?.appForm;
            if (params.value == 'SaaS') return `${params.value} (${appForm||'No form'})`;
            else return params.value;
          }
        },
        {
          field: "admin",
          headerName: "Gestor",
          valueGetter: (params) => params.value ? params.value.firstName : false,
          renderCell: (params) => {
            return params.value ? (
              <Chip avatar={<Avatar>{params.value.substr(0,1)}</Avatar>} label={params.value} />
            ) : <div/>;
          },
          flex: 1
        },
        {
          field: "status",
          headerName: "Estado",
          renderCell: (params) => {
            switch (params.value) {
              case 'Pending':
                return <Chip color="primary" size="small" icon={<LockClockIcon />} label={params.value} />;
              case 'Contacted':
                return <Chip color="info" size="small" icon={<MailIcon />} label={params.value} />;
              case 'Accepted':
                return <Chip color="success" size="small" icon={<ShoppingBasketIcon />} label={params.value} />;
              case 'Cancelled':
                return <Chip color="error" size="small" icon={<DeleteForeverIcon />} label={params.value} />;
            }
          },
          flex: 1
        },
        {
          field: "createdAt",
          headerName: "Creación",
          flex: 1,
          renderCell: (params) => {
            const createdAt = params.getValue(params.id, "createdAt");
            return nativeMoment.tz(createdAt, 'Europe/Madrid').format('L HH:mm');
          }
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "",
        body:
          "",
        cta: "",
        action: props.handleNew,
      }}
    />
  );
}