import React, { Fragment, useEffect, useState } from 'react';

import { Switch, Chip, IconButton } from "@mui/material/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from '../../components/PageHeader';
import PageScreen from '../../components/PageScreen';
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as virtualRoomsActionCreators from "../../data/virtualRooms/actions";
import HeaderButton from '../../components/HeaderButton';

import FeathersHelper from "../../helpers/feathers";

const BASE_QUERY = {
  $sort: { position: 1 }
};

const VirtualRooms = props => {

  const [modalManifest, setModalManifest] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const initialLoad = (setLoading = true) => {
    props.actions.virtualRooms.findItems({
      query: {
        ...BASE_QUERY
      }
    }, setLoading);
    FeathersHelper.find('modules', { query: { $limit: -1 } }).then( m => {
      setModalManifest([
        [
          {
            key: "slug",
            type: "string",
            label: "URL de la sala",
            help: "Nombre url-friendly de la sala",
          },
        ],
        [
          {
            key: "access.private",
            type: "switch",
            label: "Sala privada",
            help: "Sólo se podrá acceder conociendo la URL",
          },
        ],
        [
          {
            key: "image",
            type: "image",
            aspectRatio: 1.8,
            borderRadius: 10,
            width: 520,
            height: 290,
            label: "Miniatura de la sala",
            help: "Miniatura de la sala virtual",
          },
        ],
        [
          {
            key: "name",
            type: "string",
            label: "Nombre de la sala (interno)",
            help: "Nombre de la sala virtual",
          },
        ],
        [
          {
            key: "title",
            multilang: true,
            type: "string",
            label: "Título de la sala (público)",
            help: "Título de la sala en el frontend.",
          },
        ],
        [
          {
            key: "titleHidden",
            type: "switch",
            label: "Esconder título",
            help: "No mostrar título en el interior de la sala",
          },
        ],
        [
          {
            key: "enableReactions",
            type: "switch",
            label: "Habilitar reacciones",
            help: "Habilitar el módulo de reacciones live con emojis",
          },
        ],
        [
          {
            key: "description",
            multilang: true,
            type: "text",
            label: "Descripción de la sala",
            help: "Descripción de la sala en el frontend.",
          },
        ],
        [
          {
            key: "embedType",
            type: "select",
            options: [
              { key: 'none', label: 'Ninguna' },
              { key: 'external', label: 'Externa' },
              { key: 'whereby', label: 'Whereby' },
            ],
            label: "Tipo de integración",
            help: "Elige qué tipo de integración quieres para la sala",
          },
        ],
        [
          {
            key: "embedRef",
            type: "string",
            multilang: true,
            label: "Referencia",
            help: "Referencia / URL de la integración",
          },
        ],
        [
          {
            key: "modules",
            type: "multiCheck",
            label: "Módulos",
            options: m.map( mod => ({ _id: mod._id, label: mod.name }) ),
            help: "Módulos visibles en la sala virtual",
          },
        ],
      ]);
    });
  }

  useEffect(() => {
    initialLoad();
  }, []);

  const handleNew = () => {
    setCurrentItem({
      embedType: 'none',
      modules: []
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({});
    setModalOpen(false);
  };

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Salas virtuales" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Salas virtuales"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nueva sala virtual</HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          loading={props.virtualRooms.loading}
          items={props.virtualRooms.items}
          handleReorder={(id, oldIndex, newIndex) => {
            FeathersHelper.app.service("rooms").patch(id, { reorder: true, position: newIndex+1 }).then(r => {
              initialLoad(false);
            }).catch(e => console.log(e));
          }}
          columnDefs={[
            { ref: "name", align: "left" },
            { ref: "url", align: "left" },
            { ref: "embedType", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "name":
                return "Nombre";
              case "url":
                return "Enlace";
              case "embedType":
                return "Tipo integración";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "name":
                return item[ref];
              case "url":
                return <a target="_blank" href={`${process.env.REACT_APP_FRONT_URL}${props.selectedApp.slug}/${item.slug}`} rel="noreferrer">{item.slug}</a>
              case "embedType":
                if (item[ref] == 'whereby') return "Whereby"
                else return `${item[ref]} - ${item.embedRef}`;
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar sala virtual?",
                          description: `Esta acción eliminará por completo la
                          sala virtual, así como todo su contenido asociado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.virtualRooms.remove(item);
                          })
                          .catch(() => { });
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Añada salas virtuales a su aplicación",
            body:
              "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
            cta: "+ Crear nueva sala",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        title="Crear nueva sala virtual"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.virtualRooms.create}
        patchAction={props.actions.virtualRooms.patch}
        manifest={modalManifest}
        actions={props.actions.virtualRooms}
      />
    </Fragment>
  );
}


export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    virtualRooms: state.data.virtualRooms,
  }),
  (dispatch) => ({
    actions: {
      virtualRooms: bindActionCreators(virtualRoomsActionCreators, dispatch),
    },
  })
)(VirtualRooms);