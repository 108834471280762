import React, { Fragment, useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import FeathersHelper from "../../helpers/feathers"

import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip
} from "@mui/material/";
import { Alert } from "@mui/lab/";

import { useConfirm } from "material-ui-confirm";

import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";


const Modules = (props) => {

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});

  const timer = useRef(null);

  useEffect(() => {
    setLoading(true)
    FeathersHelper.create("jobs", {
      type: 's3report'
    }).then(r => {
      setReport(r);
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      alert("Error de cálculo")
    })
  }, []);

  const percentColor = () => {
    if (!report.unusedPercent) return 'info';

    if (report.unusedPercent < 20) return 'success';
    else if (report.unusedPercent < 80) return 'warning';
    else return 'error';
  }


  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Lite" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Paper>
          <Box m={2}>
            <Typography component="h3" variant="h3">
              Amazon S3
            </Typography>
          </Box>
          {report.totalFiles ?
            <Box m={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>


                  <Box mt={4}>

                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Resumen almacenaje</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Archivos totales
                          </TableCell>
                          <TableCell align="right">{report.totalFiles}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Archivos totales usados
                          </TableCell>
                          <TableCell align="right">{report.totalUsed}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Archivos totales sin usar
                          </TableCell>
                          <TableCell align="right">{report.totalUnused}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Espacio total en S3
                          </TableCell>
                          <TableCell align="right">
                            <Chip size="small" label={report.totalSize} color="success" />
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Espacio total usado
                          </TableCell>
                          <TableCell align="right">
                            <Chip size="small" label={report.usedSize} color="info" />
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Espacio total sin usar
                          </TableCell>
                          <TableCell align="right">
                            <Chip size="small" label={report.unusedSize} color="warning" />
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Porcentaje en desuso
                          </TableCell>
                          <TableCell align="right">
                            <Chip size="small" label={Number(report.unusedPercent / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })} color={percentColor()} />
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Coste mensual desuso
                          </TableCell>
                          <TableCell align="right">
                            {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })).format(report.unusedCost)}
                          </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </Box>


                </Grid>
              </Grid>
            </Box>
            :
            <Box m={2}>
              <Typography component="p" variant="p">
                Calculando...
              </Typography>
            </Box>
          }
        </Paper>
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Modules);
