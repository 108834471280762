import React, { useState, useEffect } from "react";

import ChipCheckbox from "../ChipCheckbox";

import {
  FormGroup,
} from "@mui/material";



export default (props) => {

  return (
    <FormGroup aria-label="position" row className="ChipCheckBoxes">
      {props.options.map((o) => (
        <ChipCheckbox
          onChange={(checked) => {
            if (checked) {
              props.onChange([...props.value, o._id]);
            } else {
              props.onChange(props.value.filter((v) => v != o._id));
            }
          }}
          checked={!props.value ? false : props.value.indexOf(o._id) != -1}
          label={o.label}
        />
      ))}
    </FormGroup>
  );
};
