import React from "react";

import { Container, Box, Grid, Snackbar, IconButton } from "@mui/material";
import { Alert } from "@mui/lab";
import { Close as CloseIcon } from "@mui/icons-material";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../services/app/actions";

const PageScreen = (props) => {
  return (
    <div className={`Screen ${props.className}`}>
      {props.noContainer ?
        props.children
      :
        <Container maxWidth="false" className="ContainerGrow">
          {props.children}
        </Container>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.services.app.snack?.open}
        autoHideDuration={6000}
        onClose={() => props.actions.app.closeSnack()}
      >
        <Alert onClose={() => props.actions.app.closeSnack()} severity={props.services.app.snack?.severity||'info'}>
          {props.services.app.snack?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    services: {
      app: state.services.app
    }
  }),
  (dispatch) => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch),
    },
  })
)(PageScreen);
