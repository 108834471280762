import * as api from "./api";
import * as actionTypes from "./actionTypes";

const update = (items) => ({
  type: actionTypes.UPDATE,
  items,
});

export const append = (item) => ({
  type: actionTypes.APPEND,
  item
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const destroy = (item) => ({
  type: actionTypes.DESTROY,
  item,
});

export const setSelected = (item) => ({
  type: actionTypes.SET_SELECTED,
  item,
});

const loading = () => ({
  type: actionTypes.LOADING
});

export const findItems = (params, setLoading = true) => dispatch => {
  if(setLoading) dispatch(loading());
  api
    .find(params)
    .then((r) => {
      dispatch(update(r,))
    })
    .catch((err) => {
      console.log('Error', err);
    });
}

export const getItem = (id) => (dispatch) => api.get(id).then((r) => dispatch(setSelected(r)));

export const create = (payload) => (dispatch) =>
  {
    return api.create(payload).then((r) => dispatch(append(r))).catch(e => alert(JSON.stringify(e)));
  }

export const patch = (item, payload, params = {}) => (dispatch) =>
         {
           //alert(JSON.stringify(payload));
           if (payload._type && (!params.query || !params.query._type)) params = { ...params, query: (params.query ? { ...params.query, _type: payload._type } : { _type: payload._type }) }
           return api.patch(item._id, payload, params).then((r) => {
             dispatch(updateOne(r));
             dispatch(setSelected(r));
             console.log(JSON.stringify(r));
           });
         }

export const remove = (item) => (dispatch) =>
         api.remove(item._id).then((r) => dispatch(destroy(r)));

export const createAppointment = (id, payload) => (dispatch) => {
  // console.log("Data", { $push: { appointments: payload } });
  // console.log("Query", {
  //            _type: "agendaModules"
  //          });
  return api
           .patch(id, {
             $push: { appointments: payload },
           },
           {
             query: {
               _type: "agendaModules"
             }
           })
           .then((r) => {
             dispatch(setSelected(r));
           });
}


export const patchAppointment = (id, payload) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $set: { "appointments.$": payload },
             },
             {
               query: {
                "appointments._id": payload._id,
                _type: "agendaModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }

export const removeAppointment = (id, appointmentId) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { appointments: { _id: appointmentId } }
             },
             {
               query: {
                _type: "agendaModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }

export const removeManyAppointments = (id, appointmentIds) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { appointments: { _id: { $in: appointmentIds } } }
             },
             {
               query: {
                _type: "agendaModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }

export const createContent = (id, payload) => (dispatch) =>
         api
           .patch(id, {
             $push: { contents: payload },
           })
           .then((r) => dispatch(setSelected(r)));

export const patchContent = (id, payload) => (dispatch) =>
         {
           alert(JSON.stringify(payload))
           return api
           .patch(
             id,
             {
               $set: { "contents.$": payload },
             },
             {
              //  _id: id,
               "contents._id": payload._id,
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }



export const createGame = (id, payload) => (dispatch) => {
  return api
           .patch(id, {
             $push: { games: payload },
           },
           {
             query: {
               _type: "gamingModules"
             }
           })
           .then((r) => {
             dispatch(setSelected(r));
           }).catch(e => alert(JSON.stringify(e)));
}


export const patchGame = (id, payload) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $set: { "games.$": payload },
             },
             {
               query: {
                "games._id": payload._id,
                _type: "gamingModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r))).catch(e => alert(JSON.stringify(e)));
         }

export const removeGame = (id, gameId) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { games: { _id: gameId } }
             },
             {
               query: {
                _type: "gamingModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }

export const createBlock = (id, payload) => (dispatch) => {
  return api
           .patch(id, {
             $push: { votingBlocks: payload },
           },
           {
             query: {
               _type: "votingModules"
             }
           })
           .then((r) => {
             dispatch(setSelected(r));
           }).catch(e => alert(JSON.stringify(e)));
}


export const patchBlock = (id, payload) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $set: { "votingBlocks.$": payload },
             },
             {
               query: {
                "votingBlocks._id": payload._id,
                _type: "votingModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r))).catch(e => alert(JSON.stringify(e)));
         }

export const removeBlock = (id, blockId) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { votingBlocks: { _id: blockId } }
             },
             {
               query: {
                _type: "votingModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }


export const createTopic = (id, payload) => (dispatch) => {
  return api
           .patch(id, {
             $push: { topics: payload },
           },
           {
             query: {
               _type: "questionsModules"
             }
           })
           .then((r) => {
             dispatch(setSelected(r));
           }).catch(e => alert(JSON.stringify(e)));
}


export const patchTopic = (id, payload) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $set: { "topics.$": payload },
             },
             {
               query: {
                "topics._id": payload._id,
                _type: "questionsModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r))).catch(e => alert(JSON.stringify(e)));
         }

export const removeTopic = (id, blockId) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { topics: { _id: blockId } }
             },
             {
               query: {
                _type: "questionsModules"
               }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }