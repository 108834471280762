import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        loading: false,
        items: action.items,
        total: action.total
      };
    case actionTypes.APPEND:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
        total: action.total||state.items.length+action.items.length,
      };
    case actionTypes.PREPEND:
      return {
        ...state,
        loading: false,
        items: [action.item, ...state.items]
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => item._id === action.item._id ? action.item : item)
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => item._id !== action.item._id)
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
