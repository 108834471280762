import React, { useEffect, useState } from "react";

import { useBottomScrollListener } from "react-bottom-scroll-listener";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress
} from "@mui/material";



import DataTable from "./components/DataTable";
import DataGrid from "./components/DataGrid";
import DataTable2 from "./components/DataTable2";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/es";

export default class DataView extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      modalOpen: false,
      currentItem: {},
    };
  }

  renderEmpty = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1}}>
        <Grid container justifyContent="center">
          <Grid
            item
            md={6}
            lg={4}
            className="centered"
          >
            <Typography component="h2" variant="h2" align="center">
              {this.props.empty.title}
            </Typography>
            <Box mt={1}>
              <Typography align="center" mt={2}>
                {this.props.empty.body}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={this.props.empty.action}
              >
                {this.props.empty.cta}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderItemsGrid = () => {
    return (
      this.props.items.map((i) => (
        <Grid item md={4} sm={6} xs={12}>
          <Paper className="BelongApp">
            <Box p={3}>
              <div className="Header">
                <div className="Icon"></div>
                <div className="Buttons">
                  <Button
                    color="light"
                    variant="contained"
                    onClick={() => this.handleEdit(i)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="light"
                    variant="contained"
                    className="More"
                  >
                    ...
                  </Button>
                </div>
              </div>

              <Typography component="h3" variant="h3">
                {i.name}
              </Typography>
              <Typography component="p">
                Creada el{" "}
                <Moment locale="es" format="dddd LL">
                  {i.createdAt}
                </Moment>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))
    );
  };

  renderLoading = () => <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress size={90} color="primary" />
  </div>

  render() {

    if (this.props.loading) return this.renderLoading();
    else {
      return this.props.items.length == 0 && !this.props.useGrid ? (
        this.renderEmpty()
      ) : this.props.grid ? (
        this.renderItemsGrid()
      ) : (
            this.props.useGrid ?
            <DataGrid
              {...this.props}
              items={this.props.items.map(i => ({ ...i, id: i._id }))}
              total={this.props.total}
              tableManifest={this.props.tableManifest}
              handleEdit={this.handleEdit}
              loading={true}
              initialState={this.props.initialState}
            />
            :
            this.props.useTable2 ?
            <DataTable2
              items={this.props.items}
              total={this.props.total}
              tableManifest={this.props.tableManifest}
              handleEdit={this.handleEdit}
              {...this.props}
            />
            :
            <DataTable
              items={this.props.items}
              total={this.props.total}
              tableManifest={this.props.tableManifest}
              handleEdit={this.handleEdit}
              {...this.props}
            />
          );
    }


  }
}
