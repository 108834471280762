
export const query = {
  $aggregation: [
  ],
  $sort: { name: 1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "name",
      type: "string",
      label: "Nombre",
    },
  ],
  [
    {
      key: "isNetworking",
      type: "switch",
      label: "Mostrar en networking",
      help: "Si se activa, esta audiencia se mostrará en los filtros de networking"
    },
  ]
];