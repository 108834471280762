import React, { useEffect, useState } from 'react';

const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');

const socket = io('https://api.ubelong.io', {
      transports: ["websocket"],
      forceNew: true,
      // query: {
      //   appslug: "5e9b1e1ef159c22cecd52ea4",
      //   timestamp: timestamp,
      //   hash: digest,
      // },
    });
const app = feathers();

// Set up Socket.io client with the socket
app.configure(socketio(socket));

// Receive real-time events through Socket.io
app.service('apps')
  .on('created', app => console.log('New app created', app));

export default props => {

  const [newApp, setNewApp] = useState({});
  const [apps, setApps] = useState([]);

  useEffect(() => {

    app.service('apps').find().then(r => {
      console.log("Found", r);
      setApps(r.data);
    }).catch(e => console.log("Error finding apps", e));
  }, []);

  const createApp = e => {
    e.preventDefault();
    //Call the `messages` service
    const obj = { ...newApp, terms: { en: 'Generic terms' } };
    console.log(obj)
    app.service('apps').create(obj).then(r => {
      setNewApp({});
      setApps([...apps, r]);
    }).catch(e => console.log("Error creating app", e));
  }

  return (
    <div>
      <form onSubmit={createApp}>
        <input type="text" placeholder="Name" value={newApp.name} onChange={(e) => setNewApp({ ...newApp, name: e.target.value})} />
        <input type="text" placeholder="Title" value={newApp.title ? newApp.title.en : null} onChange={(e) => setNewApp({ ...newApp, title: { en: e.target.value } })} />
        <button type="submit">Create</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>API key</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {apps.map(a => <tr>
            <td>{a.name}</td>
            <td>{a.title.en}</td>
            <td>{a.apiKey}</td>
            <td>{a.createdAt}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  );
}