import FeathersHelper from "../../helpers/feathers";

export const find = (payload) =>
  FeathersHelper.find('posts', payload);
export const create = (payload) =>
  FeathersHelper.create('posts', payload);
export const patch = (id, payload, params = {}) => FeathersHelper.patch('posts', id, payload, params);
// TODO PATCH
export const remove = (id, params = {}) => FeathersHelper.remove('posts', id, params);

