import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  items: [],
  total: 0,
  selected: {
    langPattern: {
      default: 'es',
      available: ['es']
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        loading: false,
        items: action.items,
        total: action.total
      };
    case actionTypes.APPEND:
      return {
        ...state,
        loading: false,
        items: [...state.items, ...action.items],
        total: action.total||state.items.length+action.items.length,
      };
    case actionTypes.UPDATE_ONE:
      return {
        ...state,
        loading: false,
        items: state.items.map((item) =>
          item._id === action.item._id ? action.item : item
        ),
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item._id !== action.item._id),
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.SET_SELECTED:
      return {
        ...state,
        selected: action.item,
      };
    default:
      return state;
  }
};
