import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  People as PeopleButton
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";


export default props => {


  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar checkpoint?",
          description: `Esta acción eliminará por completo los
          checkpoints, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => { });
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'name',
          header: 'Nombre'
        }
      ]}
      importFormatter={(items) => {
        return items;
      }}
      humanize={(item) => `${item.name}`}
      importHandler={true}
      // IMPORT EXPORT END
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 2
        },
        {
          field: "type",
          headerName: "Tipo",
          valueGetter: (params) => {
            if (params.value == 'point') return 'Punto';
            if (params.value == 'area') return 'Zona';
          },
          flex: 1
        },
        {
          field: "members",
          headerName: "Gente",
          flex: 1
        },
        {
          field: "actions",
          skipExcel: true,
          flex: 0.2,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <div style={{ display: 'flex' }}>
                {/* <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <PeopleButton fontSize="small" />
                </IconButton> */}
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar checkpoint?",
                      description: `Esta acción eliminará por completo el
                      checkpoint, así como todo su contenido asociado.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => { });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            );
          }
        },
      ]}
      empty={{
        title: "Añade tu primer checkpoint",
        body:
          "Añade checkpoints a tu app.",
        cta: "+ Crear nuevo checkpoint",
        action: props.handleNew,
      }}
    />
  );
}