import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

export default props => {

  const [audiences, setAudiences] = useState({});

  useEffect(() => {
    FeathersHelper.find('audiences', { query: { $limit: -1 } }).then( a => setAudiences(a));
  }, []);

  const AudienceValue = useCallback((props) => {
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <Select
        native
        value={item.value}
        onChange={handleFilterChange}
      >
        <option value='all'>Cualquiera</option>
        <option value='none'>Ninguna</option>
        {audiences.map(a => (
          <option value={a._id}>{a.name}</option>
        ))}
      </Select>
    );

  }, [audiences]);

  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar actos?",
          description: `Esta acción eliminará por completo los
          actos, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => {});
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'startDate',
          header: 'Inicio',
          value: (item) => nativeMoment(nativeMoment.tz(item.startDate, item.timezone||'Europe/Madrid').format('YYYY-MM-DD HH:mm:ss') + ` (${item.timezone||'Europe/Madrid'})`).toDate(),
          importValue: (item) => nativeMoment.tz(nativeMoment(item.startDate).format('YYYY-MM-DD HH:mm:ss'), item.timezone||'Europe/Madrid')
        },
        {
          key: 'endDate',
          header: 'Fin',
          value: (item) => nativeMoment(nativeMoment.tz(item.endDate, item.timezone||'Europe/Madrid').format('YYYY-MM-DD HH:mm:ss') + ` (${item.timezone||'Europe/Madrid'})`).toDate(),
          importValue: (item) => nativeMoment.tz(nativeMoment(item.endDate).format('YYYY-MM-DD HH:mm:ss'), item.timezone||'Europe/Madrid')
        },
        {
          key: 'timezone',
          header: 'Zona horaria',
        },
        {
          key: 'title',
          header: 'Título',
          multilang: true
        },
        {
          key: 'body',
          header: 'Descripción',
          multilang: true
        },
        {
          key: 'roomId',
          header: 'Sala',
        },

      ]}
      importFormatter={(items) => {
        return items;
      }}
      humanize={(item) => `${item.title}`}
      importHandler={true}
      // IMPORT EXPORT END
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "title",
          headerName: "Título",
          valueGetter: (params) => params.value[props.defaultLang],
          flex: 2
        },
        {
          field: "body",
          headerName: "Descripción",
          valueGetter: (params) => params.value[props.defaultLang],
          flex: 2,
          hide: true
        },
        {
          field: "startDate",
          headerName: "Inicio",
          valueFormatter: (params) => nativeMoment.tz(params.value, params.api.getCellValue(params.id, 'timezone')||'Europe/Madrid').format('L HH:mm') + ` (${params.api.getCellValue(params.id, 'timezone')||'Europe/Madrid'})`,
          sortComparator: (v1, v2, param1, param2) => {
            return nativeMoment(param1.value).diff(nativeMoment(param2.value), 'minutes')
          },
          flex: 2
        },
        {
          field: "endDate",
          headerName: "Fin",
          valueFormatter: (params) => nativeMoment.tz(params.value, params.api.getCellValue(params.id, 'timezone')||'Europe/Madrid').format('L HH:mm') + ` (${params.api.getCellValue(params.id, 'timezone')||'Europe/Madrid'})`,
          sortComparator: (v1, v2, param1, param2) => {
            return nativeMoment(param1.value).diff(nativeMoment(param2.value), 'minutes')
          },
          flex: 2
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar acto?",
                      description: `Esta acción eliminará por completo el
                      acto.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => {});
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "Añade actos a tu agenda",
        body:
          "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
        cta: "+ Crear nuevo acto",
        action: props.handleNew,
      }}
    />
  );
}