import React, { useState } from 'react';

import {
  TextField,
  IconButton,
  Tooltip
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy"


export default props => {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <TextField
        disabled
        fullWidth
        label={`${props.definition.label}${props.definition.multilang ? ` (${props.currentLang})` : ''}`}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
          className: props.classes.label,
        }}
        placeholder={props.definition.label}
        helperText={props.error || props.definition.help}
        FormHelperTextProps={{
          className: `${props.classes.helperText} ${
            props.error ? props.classes.error : null
          }`,
        }}
        value={props.value()}
        onChange={props.onChange}
      />
      <Tooltip
        open={tooltipOpen}
        title={"Copiado al portapapeles"}
        leaveDelay={1500}
        onClose={() => setTooltipOpen(false)}
      >
        <IconButton onClick={() => {
          navigator.clipboard.writeText(props.value());
          setTooltipOpen(true);
        }} aria-label="copy" style={{ marginLeft: 10 }}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>

    </div>
  );
};