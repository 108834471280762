
export const query = {
  $aggregation: [
  ],
  $sort: { lastName: 1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "master",
      type: "switch",
      label: "Superadministrador",
    },
  ],
  [
    {
      key: "email",
      type: "string",
      label: "Email",
    },
  ],
  [
    {
      key: "firstName",
      type: "string",
      label: "Nombre",
    },
  ],
  [
    {
      key: "lastName",
      type: "string",
      label: "Apellidos",
    },
  ],
  [
    {
      key: "company",
      type: "string",
      label: "Empresa",
    },
  ],
  [
    {
      key: "password",
      type: "string",
      label: "Contraseña",
    },
  ]
];