import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import dataReducer from "./data/reducer";
import servicesReducer from "./services/reducer";

const slug =
  window.location.pathname.split("/").filter((p) => p !== "")[0] || "unknown";

const config = {
  key: "slug",
  storage,
  blacklist: ["data", "services", "app"],
};

const appReducer = persistCombineReducers(config, {
  services: servicesReducer,
  data: dataReducer,
});

const enhancer = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(appReducer, composeEnhancers(enhancer));

export const persistor = persistStore(store);

export default store;
