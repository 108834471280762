import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton, Grid } from "@mui/material/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";
import * as questionsActionCreators from "../../data/questions/actions";
import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

const BASE_QUERY = {
  $limit: 10,
  $sort: { createdAt: -1 }
};

const Questions = (props) => {


  const MODAL_MANIFEST = [
    [
      {
        key: "content",
        type: "text",
        label: "Contenido",
        help: "Contenido de la pregunta.",
      }
    ]
  ];
  const MODAL_LANG_MANIFEST = [
  ];

  const TOPIC_MODAL_MANIFEST = [
    [
      {
        key: "position",
        type: "string",
        label: "Posición",
        help: "Posición numérica del tema.",
      }
    ],
    [
      {
        key: "title",
        type: "string",
        multilang: true,
        label: "Título",
        help: "Título del tema.",
      }
    ]
  ];

  let { moduleId } = useParams();

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
    props.actions.questions.findItems({
      query: {
        ...BASE_QUERY,
        moduleId
      }
    });
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [topicCurrentItem, setTopicCurrentItem] = useState({});
  const [topicModalOpen, setTopicModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const handleTopicNew = () => {
    setTopicCurrentItem({

    });
    setTopicModalOpen(true);
  };

  const handleTopicEdit = (item) => {
    const newItem = { ...item };

    setTopicCurrentItem(newItem);
    setTopicModalOpen(true);
  };

  const handleTopicClose = () => {
    setTopicModalOpen(false);
    setTopicCurrentItem({});
  };

  const confirm = useConfirm();
  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title={props.modules.selectedItem.name}
          renderActions={() => (
            <Fragment>
              <HeaderButton to={"moderation"} target="_blank" component={Link}>
                Moderación
              </HeaderButton>
              <HeaderButton to={"report"} target="_blank" component={Link}>
                Proyección
              </HeaderButton>
            </Fragment>
          )}
        />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <DataView
              items={props.data.questions.items}
              total={props.data.questions.total}
              loadPage={(page, perPage) => props.actions.questions.findItems({ query: {
                ...BASE_QUERY,
                moduleId,
                $limit: 10,
                $skip: (perPage * page)
              } })}
              columnDefs={[
                { ref: "user", align: "left" },
                { ref: "topic", align: "left" },
                { ref: "content", align: "left" },
                { ref: "answer", align: "left" },
                { ref: "createdAt", align: "left" },
                { ref: "actions", align: "left" },
              ]}
              renderHeader={(ref) => {
                switch (ref) {
                  case "user":
                    return "Usuario";
                  case "topic":
                    return "Tema";
                  case "content":
                    return "Contenido";
                  case "answer":
                    return "Respuesta";
                  case "createdAt":
                    return "Fecha";
                  case "actions":
                    return "Acciones";
                  default:
                    return null;
                }
              }}
              renderCell={(ref, item) => {
                switch (ref) {
                  case "user":
                    return item.user ? `${item.user.firstName} ${item.user.lastName}` : 'Anónimo';
                  case "topic":
                    const topics = (props.modules.selectedItem.topics||[]).filter(t => t._id.toString() == item.topicId?.toString());
                    const topic = topics.length > 0 ? topics[0].title[props.defaultLang] : null;
                    return item.topicId ? topic : null;
                  case "content":
                    return item[ref];
                  case "answer":
                    return item.user && item.user.email ? (
                      <Fragment>
                        <a href={`mailto:${item.user.email}`}>Responder</a>
                      </Fragment>
                    ) : null;
                  case "createdAt":
                    return nativeMoment(item[ref]).format('L HH:mm');
                  case "actions":
                    return (
                      <Fragment>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="EditButton"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="DeleteButton"
                          onClick={() => {
                            confirm({
                              title: "¿Eliminar pregunta?",
                              description: `Esta acción eliminará por completo la pregunta.
                              Esta acción es permenente y no tiene marcha atrás.`,
                              confirmationText: "Eliminar",
                              confirmationButtonProps: { variant: "contained" },
                              cancellationText: "Cancelar",
                              cancellationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                props.actions.questions.remove(item);
                              })
                              .catch(() => {});
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Fragment>
                    );
                  default:
                    return null;
                }
              }}
              empty={{
                title: "Aun no hay preguntas",
                body:
                  "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
                cta: "+ Crear nuevo acto",
                action: handleNew,
              }}
            />
          </Grid>
          <Grid item xs="4">
            <div className="UserRanking">
              <div className="Header">
                <h2>Temas</h2>
                <HeaderButton onClick={handleTopicNew}>+ Nuevo tema</HeaderButton>
              </div>
              <ol>
                {(props.modules.selectedItem.topics||[]).sort((a,b) => (a.position > b.position) ? 1 : -1).map((topic, index) => (
                  <li>
                    <strong>{topic.position}</strong>
                    <span className="Info">
                      <span className="Name">{topic.title[props.defaultLang]}</span>
                    </span>
                    <div>
                      <IconButton
                          size="small"
                          aria-label="delete"
                          className="EditButton"
                          onClick={() => handleTopicEdit(topic)}
                        >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        className="DeleteButton"
                        onClick={() => {
                          confirm({
                            title: "¿Eliminar tema?",
                            description: `Esta acción eliminará por completo el tema.
                            Esta acción es permenente y no tiene marcha atrás.`,
                            confirmationText: "Eliminar",
                            confirmationButtonProps: { variant: "contained" },
                            cancellationText: "Cancelar",
                            cancellationButtonProps: { variant: "contained" },
                          })
                            .then(() => {
                              props.actions.modules.removeTopic(moduleId, topic._id);
                            })
                            .catch(() => {});
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </Grid>
        </Grid>
      </PageScreen>
      <RightModal
        newTitle="Crear pregunta"
        editTitle="Editar pregunta"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        createAction={(payload) => {}}
        patchAction={(currentItem, payload) =>
          props.actions.questions.patch(payload)
        }
      />
      <RightModal
        newTitle="Crear tema"
        editTitle="Editar tema"
        open={topicModalOpen}
        currentItem={topicCurrentItem}
        handleClose={handleTopicClose}
        manifest={TOPIC_MODAL_MANIFEST}
        createAction={(payload) => {
          return props.actions.modules.createTopic(moduleId, payload)
        }}
        patchAction={(currentItem, payload) =>
          props.actions.modules.patchTopic(moduleId, payload)
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    data: {
      questions: state.data.questions
    },
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
      questions: bindActionCreators(questionsActionCreators, dispatch),
    },
  })
)(Questions);
