import React, { Fragment, useState, useEffect } from 'react';

import { connect } from "react-redux";

import defaults from '../../defaults.json';

const _ = require("lodash");


const Form = props => {

  const isNew = !props.defaultValue || !props.defaultValue._id;
  const [currentLang, setCurrentLang] = useState(null);
  const [newObject, setNewObject] = useState({});
  const [erroredFields, setErroredFields] = useState({});

  useEffect(() => {
    setNewObject(props.defaultValue)
  }, [props.defaultValue])

  useEffect(() => {
    setCurrentLang(props.selectedApp.langPattern.default);
  }, [props.selectedApp.langPattern.default]);

  const valueForPath = (path) => _.get(newObject, path);

  const setValueForPath = (path, value) => setNewObject(newObject => {
    //console.log(`Gonna merge ${value} into ${path}`, );
    const copy = { ...newObject };
    _.set(copy, path, value);
    //console.log("newObject", copy);
    return copy;
  });

  const erroredFieldsForPath = (path) => _.get(erroredFields, path);

  const create = () => {
    setErroredFields({});
    props
      .createAction(newObject)
      .then(() => props.handleSuccess('create', newObject))
      .catch((e) => {
        //console.log("Catched", e);
        if (e.errors) {
          Object.keys(e.errors).map((k) => {
            let message;
            switch (e.errors[k].kind) {
              case "required":
                message = "Este campo es obligatorio";
            }
            if (k.indexOf(".") === -1) {
              setErroredFields((ef) => ({ ...ef, [k]: message }));
            } else {
              let base = [];
              k.split(".").map((p) => {
                base.push(p);
                setErroredFields((ef) => ({
                  ...ef,
                  [base.join(".")]: message,
                }));
              });
            }
          });
        }
      });
    //console.log("erroredFields", erroredFields);
  };

  const patch = () => {
    // const changes = {};
    // Object.keys(newObject).map((k) => {
    //   if (newObject[k] != props.currentItem[k]) changes[k] = newObject[k];
    // });
    //alert(JSON.stringify(newObject));
    props
      .patchAction(newObject)
      .then(() => props.handleClose())
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };

  const handleSave = () => {
    if (isNew) create();
    else patch();
  };

  return (
    <Fragment>
      {props.renderFields(
        newObject,
        valueForPath,
        setValueForPath,
        erroredFieldsForPath,
        handleSave,
        currentLang,
        setCurrentLang
      )}
    </Fragment>
  );

}

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
  }), null
)(Form);