import React, {} from 'react';

// OWN ROOT COMPONENTS
import { Grid, List, ListItem, ListSubheader, ListItemIcon, ListItemText, Collapse } from "@mui/material/";
import makeStyles from '@mui/styles/makeStyles';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Send as SendIcon,
  Drafts as DraftsIcon,
  Inbox as InboxIcon,
  List as ListIcon,
  AttachFile as AttachmentIcon,
  Public as WebtabIcon,
  ExpandLess,
  AddCircle,
  ExpandMore,
  StarBorder,
  NavigateNext,
  Create,
  VerticalSplit
} from "@mui/icons-material/";


// OTHER UTILS
import { ReactSortable } from "react-sortablejs";
const _ = require("lodash");

// STYLING
const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column'
  },
  basic: {
    backgroundColor: '#fff'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingBottom: 20,
    border: '1px dashed #BBB'
  },
  emptyList: {
    backgroundColor: '#fff',
    paddingVertical: 30,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  fullList: {
    padding: 0
  }
}));

const Icon = props => {
  switch(props.type) {
    case 'list':
      return <ListIcon />;
    case 'attachment':
      return <AttachmentIcon />
    case 'external':
      return <WebtabIcon />
    case 'general':
      return <VerticalSplit />
  }
}

const StaticContentListItem = ({ content, className, setSelectedPath, setContents, basePath, index, clone }) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const setSubContents = (subcontents) => setContents(oldContents => {
    const copy = { ...oldContents };
    _.set(copy, `${basePath}.subcontents`, subcontents);
    //alert(JSON.stringify(Object.values(copy)));
    return Object.values(copy);
  });

  return (
    <div
      className={classes.wrap}
    >
      <ListItem
        ContainerComponent="div"
        className={`${classes.basic} ${className}`}
        onClick={() => {
          setSelectedPath(`${basePath}`);
        }}
      >
        <ListItemIcon >
          <Icon type={content.contentType} />
        </ListItemIcon>
        <ListItemText primary={content.title.es} />
        {content.contentType === '__list' ?
          <NavigateNext  />
        :
          null
        }
      </ListItem>
      {content.contentType === 'list' ?
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            className={content.subcontents.length == 0 && !clone ? classes.emptyList : classes.fullList}
          >
            <ReactSortable
              list={content.subcontents}
              setList={setSubContents}
              group="groupName"
              emptyInsertThreshold={10}

              className={classes.nested}
            >
              {content.subcontents.map( (sc, j) => (
                <StaticContentListItem content={sc} key={sc._id} setSelectedPath={setSelectedPath} setContents={setContents} basePath={`${basePath}.subcontents[${j}]`} />
              ))}
            </ReactSortable>

          </List>
        </Collapse>
            : null}
    </div>
  );
}

export default StaticContentListItem;