import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton } from "@mui/material/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as announcementsActionCreators from "../../data/announcements/actions";
import HeaderButton from "../../components/HeaderButton";

import Moment from "react-moment";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

const Announcements = (props) => {
  const MODAL_MANIFEST = [
    [
      {
        key: "title",
        multilang: true,
        type: "string",
        label: "Título",
        help: "Título del destacado",
      },
    ],
    [
      {
        key: "body",
        multilang: true,
        type: "text",
        label: "Texto",
        help: "Texto del destacado",
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [

  ];

  useEffect(() => {
    props.actions.announcements.findItems({});
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({});
    setModalOpen(false);
  };

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}/` },
          { title: "Destacados" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Destacados"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>
                + Nuevo destacado
              </HeaderButton>
            </Fragment>
          )}
        />
        <DataView
          loading={props.announcements.loading}
          items={props.announcements.items}
          columnDefs={[
            { ref: "title", align: "left", style: {width: '30%'} },
            { ref: "createdAt", align: "left" },
            { ref: "actions", align: "left" },
          ]}
          renderHeader={(ref) => {
            switch (ref) {
              case "title":
                return "Título";
              case "createdAt":
                return "Hora";
              case "actions":
                return "Acciones";
              default:
                return null;
            }
          }}
          renderCell={(ref, item) => {
            switch (ref) {
              case "title":
                return (
                  <Fragment>
                    <strong>{item.title.es}</strong>
                    <br />{item.body.es}
                  </Fragment>
                );
              case "createdAt":
                return nativeMoment(item.createdAt).format('L HH:mm');
              case "actions":
                return (
                  <Fragment>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="EditButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      className="DeleteButton"
                      onClick={() => {
                        confirm({
                          title: "¿Eliminar destacado?",
                          description: `Esta acción eliminará por completo el
                          destacado.
                          Esta acción es permenente y no tiene marcha atrás.`,
                          confirmationText: "Eliminar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        })
                          .then(() => {
                            props.actions.announcements.remove(item);
                          })
                          .catch(() => {});
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Fragment>
                );
              default:
                return null;
            }
          }}
          empty={{
            title: "Envía destacados a tus usuarios",
            body:
              "Muestra novedades en la home de tu app mediante los destacados.",
            cta: "+ Crear nuevo destacado",
            action: handleNew,
          }}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear nuevo destacado"
        editTitle="Editar destacado"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        createAction={props.actions.announcements.create}
        patchAction={props.actions.announcements.patch}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    announcements: state.data.announcements,
  }),
  (dispatch) => ({
    actions: {
      announcements: bindActionCreators(announcementsActionCreators, dispatch),
    },
  })
)(Announcements);
