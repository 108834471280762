import React, { Fragment, useState, useEffect } from 'react';

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";
import RightModal from "../../components/RightModal/v2";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";

import { Link, useParams } from "react-router-dom";

import * as DataSource from './DataSource';
import List from './List';

const Appointments = (props) => {

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({
      timezone: 'Europe/Madrid'
    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };
    if (!newItem.timezone) {
      newItem.timezone = 'Europe/Madrid';
    }
    // if (newItem.startDate) {
    //   newItem.startDate = nativeMoment.tz(newItem.startDate, newItem.timezone||'Europe/Madrid').format('YYYY-MM-DDTHH:mm:ss');
    // }
    // if (newItem.endDate) {
    //   newItem.endDate = nativeMoment.tz(newItem.endDate, newItem.timezone||'Europe/Madrid').format('YYYY-MM-DDTHH:mm:ss');
    // }
    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  let { moduleId } = useParams();

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
  }, []);

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title="Agenda"
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo acto</HeaderButton>
            </Fragment>
          )}
        />
        <List
          loading={props.modules.loading}
          items={(props.modules.selectedItem.appointments || []).sort( (a,b) => a.startDate > b.startDate ? 1 : -1)}
          total={0}
          handleNew={handleNew}
          handleCreate={(item) => props.actions.modules.createAppointment(moduleId, item)}
          handleEdit={handleEdit}
          handlePatch={(item) => props.actions.modules.patchAppointment(moduleId, item)}
          handleRemove={(item) => props.actions.modules.removeAppointment(moduleId, item._id)}
          handleRemoveMany={(ids) => props.actions.modules.removeManyAppointments(moduleId, ids)}
          defaultLang={props.defaultLang}
          langs={props.selectedApp.langPattern.available}
        />
      </PageScreen>
      <RightModal
        newTitle="Crear acto"
        editTitle="Editar acto"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={DataSource.modalManifest}
        createAction={(payload) => {
          return props.actions.modules.createAppointment(moduleId, payload)
        }}
        patchAction={(currentItem, payload) =>
          props.actions.modules.patchAppointment(moduleId, payload)
        }
      />
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
    },
  })
)(Appointments);