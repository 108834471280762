import React, { Fragment, useCallback, useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";


import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  TextField,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Modal
} from "@mui/material/";
import { Alert } from "@mui/lab/";

import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";


import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";

import FeathersHelper from "../../helpers/feathers"

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import ReactJsonPrint from "react-json-print";


const Migrate = (props) => {

  const [posts, setPosts] = useState({ data: [], total: false })
  const [users, setUsers] = useState({ data: [], total: false })
  const [modules, setModules] = useState({ data: [], total: false })

  const loadPosts = (skip = 0) => {
    FeathersHelper.find("posts", {
      query: {
        $limit: 1,
        $skip: skip
      }
    }).then(r => {
      setPosts(p => {
        const all = [...p.data, ...r.data]
        return { total: r.total, data: all }
      })
    })
  }
  useEffect(() => {
    if (!posts.total || posts.total > (posts.data?.length || 0)) {
      loadPosts(posts.data.length)
    }
  }, [posts])

  const loadUsers = (skip = 0) => {
    console.log("loadusers")
    FeathersHelper.find("users", {
      query: {
        $limit: 1,
        $skip: skip
      }
    }).then(r => {
      setUsers(p => {
        const all = [...p.data, ...r.data]
        return { total: r.total, data: all }
      })
    })
  }
  useEffect(() => {
    if (!users.total || users.total > (users.data?.length || 0)) {
      loadUsers(users.data.length)
    }
  }, [users])

  const loadModules = () => {
    FeathersHelper.find("modules").then(r => {
      setModules({ data: r, total: r.length })
    })
  }

  useEffect(() => {
    loadModules()
  }, [])


  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Migrate" },
        ]}
      />
      <PageScreen className="Migrate">
        <Paper>
          <Box m={3}>
            <Box m={2}>
              <Typography component="h3" variant="h3">
                uBelong Migrate
              </Typography>
            </Box>
            <Box m={2}>
              <p>Users: {JSON.stringify(users.total)}</p>
              <p>Posts: {JSON.stringify(posts.total)}</p>
              <p>Modules: {JSON.stringify(modules.total)}</p>
            </Box>
            <Box m={2}>
              <table>
                <thead>
                  <tr>
                    <th>Entity</th>
                    <th>Field</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.data.map(u => (
                    <tr key={u._id}>
                      <td>User</td>
                      <td>Picture</td>
                      <td>{u.picture}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>

          </Box>
        </Paper>

      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Migrate);
