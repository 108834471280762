import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";


import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";
import PageHeader from "../../components/PageHeader";
import HeaderButton from "../../components/HeaderButton";

import {  TextField, Grid } from '@mui/material';

import QRCode from "react-qr-code";


import FeathersHelper from '../../helpers/feathers'


const QR = (props) => {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [users, setUsers] = useState([]);

  const load = () => {

  }

  const searchChanged = (e) => {
    const t = e.target.value;
    if (!t || t == '') {
      setUsers([]);
      setSelectedIndex(0);
    } else {
      FeathersHelper.find("users", {
        query: {
          fastpassEnabled: true,
          $or: [
            { firstName: { $like: t, $search: t } },
            { lastName: { $like: t, $search: t } },
            { subtitle: { $like: t, $search: t } },
            { email: { $like: t, $search: t } },
          ],
        }
      }).then(r => {
        setSelectedIndex(0);
        setUsers(r.data);
      })
    }

  }

  const keyDown = (e) => {
    if (e.key == 'ArrowDown') {
      e.preventDefault();
      if (selectedIndex < users.length - 1) setSelectedIndex(selectedIndex+1)
    }
    if (e.key == 'ArrowUp') {
      e.preventDefault();
      if (selectedIndex > 0) setSelectedIndex(selectedIndex-1)
    }
  }

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Analíticas" },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title={"QRs"}
          renderActions={() => (
            <Fragment>
            </Fragment>
          )}
        />
        <TextField
          id="outlined-basic"
          label="Buscar por nombre/apellidos"
          variant="outlined"
          onChange={searchChanged}
          onKeyDown={keyDown}
        />
        <Grid container spacing={2}>
          <Grid item xs={8}>
              <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th style={{ padding: 10, backgroundColor: '#eee' }} align="left" width="30%">Nombre</th>
                    <th style={{ padding: 10, backgroundColor: '#eee' }} align="left" width="40%">Apellidos</th>
                    <th style={{ padding: 10, backgroundColor: '#eee' }} align="left" width="30%">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, index) => (
                    <tr key={u._id} style={{ backgroundColor: selectedIndex == index ? 'blue' : 'transparent' }}>
                      <td style={{ padding: 10, color: selectedIndex == index ? 'white' : '#333', fontWeight: selectedIndex == index ? 'bold' : 'normal' }}>{u.firstName}</td>
                      <td style={{ padding: 10, color: selectedIndex == index ? 'white' : '#333', fontWeight: selectedIndex == index ? 'bold' : 'normal' }}>{u.lastName}</td>
                      <td style={{ padding: 10, color: selectedIndex == index ? 'white' : '#333', fontWeight: selectedIndex == index ? 'bold' : 'normal' }}>{u.email}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
          </Grid>
          <Grid item xs={4}>
            {users.length > 0 && users[selectedIndex] ?
              <div style={{
                backgroundColor: '#ffffff',
                padding: 90
              }}>
                <QRCode
                  value={users[selectedIndex]._id}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
                />
                <p>{users[selectedIndex].firstName} {users[selectedIndex].lastName}</p>
              </div>
              : null
            }

          </Grid>
        </Grid>
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
  }),
  null
)(QR);
