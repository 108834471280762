import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/Footer';

export default props => (
  <React.Fragment>
    <Outlet />
    <Footer />
  </React.Fragment>
)