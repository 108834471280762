import React, { Fragment, useCallback, useState, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import { ResponsiveScatterPlotCanvas } from '@nivo/scatterplot';

import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  TextField,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Modal
} from "@mui/material/";
import { Alert } from "@mui/lab/";

import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";


import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";

import FeathersHelper from "../../helpers/feathers"

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";
import ReactJsonPrint from "react-json-print";

const PointRow = ({item, index, ...props}) => {

  const [showParams, setShowParams] = useState(false);

  const statusColor = useCallback((_code) => {
    const code = parseInt(_code);
    if (code < 200) return 'info';
    else if (code < 300) return 'success';
    else if (code < 400) return 'primary';
    else return 'error';
  }, [])

  return (
    <React.Fragment>
      <TableRow key={`${index}-main`}>
        <TableCell component="th" scope="row">
          {}
          {nativeMoment(item._time).locale('es').format('lll')}
        </TableCell>
        <TableCell>{item.appId||'-'}</TableCell>
        <TableCell>{item.userInfo}</TableCell>
        <TableCell>{item.provider}</TableCell>
        <TableCell>{item.path}#{item.verb}</TableCell>
        <TableCell style={{ overflow: 'hidden' }}>
          <div onClick={() => setShowParams(sp => !sp)} style={{ maxWidth: 200, borderRadius: 5, overflow: 'hidden', cursor: 'pointer', backgroundColor: '#eee', minHeight: 30, maxHeight: showParams ? 999999 : 30}}>

            <span style={{margin: 8, display: 'block', fontSize: 10, fontFamily: 'monospace', wordBreak: showParams ? "break-all" : 'no-break', whiteSpace: showParams ? 'normal' : 'nowrap'}}>
              {item.params}
            </span>
          </div>
        </TableCell>
        <TableCell><Chip color={statusColor(item.status)} label={item.status} /></TableCell>
        <TableCell>
          <Typography color={item._value < 1000 ? 'success.main' : 'error'}>
          {
            item._value >= 1000 ?
              `${item._value}s`
              :
              `${item._value * 1000}ms`
          }
          </Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ReqTable = ({ data, ...props }) => {
  return (
    <TableContainer component={Paper} className="DataTable">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Time</TableCell>
            <TableCell align="left">Slug</TableCell>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Provider</TableCell>
            <TableCell align="left">Path</TableCell>
            <TableCell align="left">Params</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Response time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <PointRow item={item} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Monitor = (props) => {

  const [slowest24, setSlowest24] = useState([]);
  const [slowest1, setSlowest1] = useState([]);

  const [scatterRange, setScatterRange] = useState("-1h");
  const [scatterData, setScatterData] = useState([]);
  const [scatterLoading, setScatterLoading] = useState(true);
  const [showPointModal, setShowPointModal] = useState(false);
  const [pointModalContent, setPointModalContent] = useState("");

  // SLOWEST LAST 24
  useEffect(() => {
    FeathersHelper.find("influx", { query: {
      operation: 'slowest',
      range: '-24h'
    }}).then(r => {
      setSlowest24(r);
    })
  }, [])

  // SLOWEST LAST HOUR
  useEffect(() => {
    FeathersHelper.find("influx", { query: {
      operation: 'slowest',
      range: '-1h'
    }}).then(r => {
      setSlowest1(r);
    })
  }, [])

  // SCATTER DATA
  useEffect(() => {
    setScatterLoading(true);
    FeathersHelper.find("influx", { query: {
      operation: 'scatter',
      range: scatterRange
    }}).then(r => {
      setScatterData(r);
      setScatterLoading(false);
    }).catch(e => {
      setScatterLoading(false);
    })
  }, [scatterRange])

  const handleScatterRange = (event, newRange) => {
    setScatterRange(newRange);
  };

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Lite" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Paper>
          <Box m={3}>
            <Box m={2}>
              <Typography component="h3" variant="h3">
                uBelong Monitoring
              </Typography>
            </Box>
            <Box mx={2} mb={5} mt={2}>
              <Typography component="h4" variant="h3">
                Response time
              </Typography>
              <Box mt={3}>
                <ToggleButtonGroup
                  value={scatterRange}
                  exclusive
                  onChange={handleScatterRange}
                  aria-label="text alignment"
                >
                  <ToggleButton value="-1h" aria-label="left aligned">
                    Última hora
                  </ToggleButton>
                  <ToggleButton value="-6h" aria-label="centered">
                    Últimas 6 horas
                  </ToggleButton>
                  <ToggleButton value="-24h" aria-label="right aligned">
                    Último día
                  </ToggleButton>
                  <ToggleButton value="-7d" aria-label="justified">
                    Últimos 7 días
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <div style={{ width: '100%', height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {scatterLoading ?
                  <CircularProgress />
                  :
                  <ResponsiveScatterPlotCanvas
                    data={scatterData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{
                      type: "time",
                      format: "%Y-%m-%d %H:%M"
                    }}
                    xFormat="time:%Y-%m-%d %H:%M"
                    yScale={{
                      type: "linear",
                      min: "auto",
                      max: "auto",
                      stacked: false,
                      reverse: false
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                      orient: "left",
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Response time in ms",
                      legendOffset: -40,
                      legendPosition: "middle"
                    }}
                    axisBottom={{
                      format: "%b %d %H:%M",
                      //tickValues: "every 2 days",
                      // tickRotation: -90,
                      legend: "time scale",
                      legendOffset: 40
                    }}
                    colors={{ scheme: "nivo" }}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    onClick={node => {
                      setPointModalContent(
                        <dl>
                          <dt>Time</dt>
                          <dd>{node.formattedX}</dd>
                          <dt>Provider</dt>
                          <dd>{node.data.provider}</dd>
                          <dt>Path</dt>
                          <dd>{node.data.path}#{node.data.verb}</dd>
                          <dt>Params</dt>
                          <dd style={{ wordBreak: 'break-all' }}>{node.data.params}</dd>
                          <dt>User</dt>
                          <dd>{node.data.userInfo}</dd>
                          <dt>Status code</dt>
                          <dd>{node.data.status}</dd>
                          <dt>Response time</dt>
                          <dd>{node.formattedY}ms</dd>
                        </dl>
                      );
                      setShowPointModal(true);
                    }}
                    tooltip={item => {
                      return (
                        <div style={{ padding: 5, border: '1px solid #eee', backgroundColor: '#fff', boxShadow: '0px 0px 5px 1px #e5e5e5', overflow: 'hidden' }}>
                          <dl>
                            <dt>Time</dt>
                            <dd>{item.node.formattedX}</dd>
                            <dt>Path</dt>
                            <dd>{item.node.data.path}#{item.node.data.verb}</dd>
                            <dt>Response time</dt>
                            <dd>{item.node.formattedY}ms</dd>
                          </dl>
                        </div>
                      );
                    }}
                  />
                }
              </div>
            </Box>
            <Modal
            open={showPointModal}
            onClose={() => setShowPointModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              {pointModalContent}
            </Box>
          </Modal>
            <Box mx={2} my={5}>
              <Typography component="h4" variant="h3">
                Slowest last hour
              </Typography>
              <ReqTable data={slowest1} />
            </Box>
            <Box mx={2} my={5}>
              <Typography component="h4" variant="h3">
                Slowest last 24h
              </Typography>
              <ReqTable data={slowest24} />
            </Box>
          </Box>
        </Paper>

      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Monitor);
