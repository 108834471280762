
import * as actionTypes from "./actionTypes";

export const update = (app) => ({
  type: actionTypes.UPDATE,
  app,
});

export const setAlert = (alert) => ({
  type: actionTypes.SET_ALERT,
  alert,
});

export const closeAlert = () => ({
  type: actionTypes.CLOSE_ALERT
});

export const setSnack = (snack) => ({
  type: actionTypes.SET_SNACK,
  snack,
});

export const closeSnack = () => ({
  type: actionTypes.CLOSE_SNACK
});