import React, { Fragment, useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import FeathersHelper from "../../helpers/feathers"

import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip
} from "@mui/material/";
import { Alert } from "@mui/lab/";

import { useConfirm } from "material-ui-confirm";

import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";


const Modules = (props) => {

  const [minTier, setMinTier] = useState(null);
  const [maxTier, setMaxTier] = useState(null);
  const [settings, setSettings] = useState('');

  const timer = useRef(null);

  const confirm = useConfirm();

  useEffect(() => {
    FeathersHelper.get("atlas", 'settings').then(r => {
      setSettings(r);
    })
  }, []);

  useEffect(() => {
    if (settings) {
      if (settings.stateName == 'UPDATING' && !timer.current) {
        timer.current = setInterval(() => {
          console.log("Refreshing");
          FeathersHelper.get("atlas", 'settings').then(r => {
            setSettings(r);
          })
        }, 10000)
      }
      if (settings.stateName == 'IDLE' && timer.current) {
        clearInterval(timer.current);
        console.log("Cleared interval");
      }
    }
  }, [settings]);

  const saveChanges = () => {
    confirm({
      title: "Confirmar cambios?",
      description: `Esta acción iniciará el escalado quedando la configuración de manera permanente, hasta nuevo cambio, al coste elegido segun la tabla adjunta.`,
      confirmationText: "Confirmar",
      confirmationButtonProps: { variant: "contained" },
      cancellationText: "Cancelar",
      cancellationButtonProps: { variant: "contained" },
    })
      .then(() => {
        FeathersHelper.patch("atlas", 'settings', {
          "min": minTier,
          "max": maxTier
      }).then(r => {
          setSettings(r);
        })
      })
      .catch(() => {});
  }


  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Lite" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Paper>
          <Box m={2}>
            <Typography component="h3" variant="h3">
              MongoDB Atlas
            </Typography>
          </Box>
          {settings.providerSettings ?
            <Box m={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Plan mínimo</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={minTier||(settings.providerSettings ? settings.providerSettings.autoScaling.compute.minInstanceSize : null)}
                      label="Min tier"
                      onChange={(event) => { setMinTier(event.target.value) }}
                    >
                      <MenuItem value="M10">M10</MenuItem>
                      <MenuItem value="M20">M20</MenuItem>
                      <MenuItem value="M30">M30</MenuItem>
                      <MenuItem value="M40">M40</MenuItem>
                      <MenuItem value="M50">M50</MenuItem>
                      <MenuItem value="M60">M60</MenuItem>
                      <MenuItem value="M80">M80</MenuItem>
                      <MenuItem value="M140">M140</MenuItem>
                      <MenuItem value="M200">M200</MenuItem>
                      <MenuItem value="M300">M300</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Plan máximo</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={maxTier||(settings.providerSettings ? settings.providerSettings.autoScaling.compute.maxInstanceSize : null)}
                      label="Max tier"
                      onChange={(event) => { setMaxTier(event.target.value) }}
                    >
                      <MenuItem value="M10">M10</MenuItem>
                      <MenuItem value="M20">M20</MenuItem>
                      <MenuItem value="M30">M30</MenuItem>
                      <MenuItem value="M40">M40</MenuItem>
                      <MenuItem value="M50">M50</MenuItem>
                      <MenuItem value="M60">M60</MenuItem>
                      <MenuItem value="M80">M80</MenuItem>
                      <MenuItem value="M140">M140</MenuItem>
                      <MenuItem value="M200">M200</MenuItem>
                      <MenuItem value="M300">M300</MenuItem>
                    </Select>
                  </FormControl>

                  <small><a href="https://www.mongodb.com/mongodb-on-aws-pricing#mongodb-aws-pricing-by-instance-type" target="_blank" rel="noreferrer">Ver pricing</a></small>

                  {settings.stateName == 'IDLE' ?
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <Button color="primary" variant="contained" onClick={saveChanges}>Confirmar cambios</Button>
                    </Box>
                  :
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <Button color="primary" disabled variant="contained">En proceso...</Button>
                    </Box>
                  }

                  <Box mt={4}>

                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Resumen estado</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Estado escalado
                          </TableCell>
                          <TableCell align="right">
                            {settings.stateName == 'UPDATING' && <Chip size="small" label="Actualizando" color="warning" />}
                            {settings.stateName == 'IDLE' && <Chip size="small" label="Estable" color="success" />}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Plan mínimo
                          </TableCell>
                          <TableCell align="right">{settings.providerSettings.autoScaling.compute.minInstanceSize}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Plan máximo
                          </TableCell>
                          <TableCell align="right">{settings.providerSettings.autoScaling.compute.maxInstanceSize}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            Plan actual
                          </TableCell>
                          <TableCell align="right">{settings.providerSettings.instanceSizeName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>


                </Grid>
              </Grid>
            </Box>
          : null }
        </Paper>
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Modules);
