import React, { useState, useEffect, Fragment } from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  IconButton
} from "@mui/material";

import {
  Search as SearchIcon,
  Clear as ClearIcon
} from "@mui/icons-material"
import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import HeaderButton from "../../components/HeaderButton";

import { Link, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";

import { useBottomScrollListener } from "react-bottom-scroll-listener";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/es";
import { ScaledImage, scaledImageSource } from '../../components/ScaledImage';



const Home = props => {

  const query = React.useRef('');

  const navigate = useNavigate();

  useBottomScrollListener(() => {
    //alert("Bottom!");
  })

  useEffect(() => {
    props.actions.apps.findItems({ query: { $sort: { updatedAt: -1 } } });
  }, []);

  const loadMore = () => {
    if (query.current && query.current != '') {
      props.actions.apps.findItemsAndAppend({ query: {
        $or: [
          { name: { $like: query.current, $search: query.current } },
        ],
        $sort: { updatedAt: -1 },
        $skip: props.apps.items.length
      } });
    } else {
      props.actions.apps.findItemsAndAppend({ query: { $sort: { updatedAt: -1 }, $skip: props.apps.items.length } });
    }

  };

  const onSearchChange = (e) => {
    query.current = e.target.value;
    props.actions.apps.findItems({ query: {
      $or: [
        { name: { $like: e.target.value, $search: e.target.value } },
      ],
      $sort: { updatedAt: -1 } }
    });
  }

  const renderEmpty = () => {
    return (
      <Container maxWidth="false" className="ContainerGrow">
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item md={6} lg={4} className="centered">
              <Typography component="h2" variant="h2" align="center">
                Crea una aplicación móvil atractiva para tus eventos
              </Typography>
              <Box mt={1}>
                <Typography align="center" mt={2}>
                  Construye rápidamente y previsualiza al instante Todas las
                  funciones están incluidas, solo cree y publique Go live en
                  minutos y comience a incorporar asistentes
                </Typography>
              </Box>
              <Box mt={2}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  + Crear nueva App
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
    );
  };

  const renderItems = () => {
    return (
        <Box pb={4} className="Holi" maxWidth="false">
          <Grid container  spacing={4}>
            {props.apps.items.map((i) => (
              <Grid item md={4} sm={6} xs={12}>
                <Paper onClick={() => window.location = `/${i.slug}/` } component={Paper} className="BelongApp">
                  <Box p={3}>
                    <div className="Header">
                      <div
                        className="Icon"
                        style={{
                          backgroundImage: `url(${scaledImageSource(i.icon, 120, 120)})`,
                        }}
                      ></div>

                      <div className="Buttons">
                        <Typography component="p">
                          Mod. <Moment fromNow>{i.updatedAt}</Moment>
                        </Typography>
                        {/*<Button color="light" variant="contained">
                          Edit
                        </Button>
                        <Button
                          color="light"
                          variant="contained"
                          className="More"
                        >
                          ...
                        </Button>*/}
                      </div>
                    </div>

                    <Typography component="h3" variant="h3">
                      {i.name}
                    </Typography>
                    <Typography component="p">
                      Creada el{" "}
                      <Moment locale="es" format="dddd LL">
                        {i.createdAt}
                      </Moment>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
            {/*
            <Grid item md={4} sm={6} xs={12}>
              <Paper className="BelongApp New" onClick={() => navigate(`/new`)}>
                <Box p={3}>
                  <Typography component="h3" variant="h3" align="center">
                    +<br />
                    Crear nueva aplicación
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            */}
            {props.apps.items.length < props.apps.total ?
              <Grid item md={4} sm={6} xs={12}>
                <Paper className="BelongApp New" onClick={loadMore}>
                  <Box p={3}>
                    <Typography component="h3" variant="h3" align="center">
                      Cargar más
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            : null }
          </Grid>
        </Box>
    );
  };


  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
        ]}
        isHome
      />
      <PageScreen className="Home">
        <PageHeader
          title="Apps"
          renderActions={() => (
            <Box sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              flex: 1
            }}>
              <HeaderButton component={Link}
                to={'/new'}>+ Nueva app</HeaderButton>
              <TextField
                variant="standard"
                //value={query}
                onChange={onSearchChange}
                placeholder="Buscar…"
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: props.value ? 'visible' : 'hidden' }}
                      onClick={props.clearSearch}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                className="NoMargins"
                sx={{
                  margin: "0 !important",
                  width: {
                    xs: 1,
                    sm: 'auto',
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  '.MuiFormControl-root': {
                    margin: "0 !important",
                  },
                  '& .MuiInput-root': {
                    margin: "0 !important",
                  },
                  '& .MuiSvgIcon-root': {
                    mr: 0.5,
                  },
                  '& .MuiInput-underline:before': {
                    borderBottom: 1,
                    borderColor: 'divider',
                  },
                }}
              />
            </Box>
          )}
        />
        {props.apps.length == 0 ? renderEmpty() : renderItems()}
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    apps: state.data.apps,
    app: state.services.app
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(Home);