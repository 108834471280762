
export const query = {
  $aggregation: [
  ],
  $sort: { name: 1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "name",
      type: "string",
      label: "Nombre",
    },
  ],
  [
    {
      key: "type",
      type: "select",
      options: [
        { key: 'area', label: 'Zona de control' },
        { key: 'point', label: 'Punto de control' },
      ],
      label: "Tipo de checkpoint",
      help: "Elige qué tipo de checkpoint quieres",
    },
  ],
  [
    {
      key: "permission",
      type: "audienceVisibility",
      label: "Permisos",
      help: "Elige quién puede acceder a este checkpoint",
    },
  ],
];