import React, {} from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { makeStyles } from "@mui/styles";

export default props => {
  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <FormControl
        variant="outlined"
        //className={classes.formControl}
        fullWidth
      >
        <Select
          displayEmpty
          value={props.value()}
          onChange={props.onChange}
        >
          {props.definition.options.map((o) => (
            <MenuItem value={o.key}>{o.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};