import { initializeApp } from "firebase/app";
import { getPerformance, trace } from "firebase/performance";

class FirebaseHelper {
  static instance = null;

  constructor() {
    if (FirebaseHelper.instance) {
      return FirebaseHelper.instance;
    }

    this._type = "FirebaseHelper";
    FirebaseHelper.instance = this;

    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDOImKTPTnKi-4kcLlhzITHsWunXy5IqzM",
      authDomain: "ubelong-2020.firebaseapp.com",
      databaseURL: "https://ubelong-2020.firebaseio.com",
      projectId: "ubelong-2020",
      storageBucket: "ubelong-2020.appspot.com",
      messagingSenderId: "1088687700944",
      appId: "1:1088687700944:web:8eb38208186c968aef97d5"
    };

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);

    // Initialize Performance Monitoring and get a reference to the service
    this.perf = getPerformance(this.app);

    console.log("FIREBASE HELPER INTITALIZED")

  }

  trace = (traceName) => {
    return trace(this.perf, traceName);
  }

}

export default new FirebaseHelper();