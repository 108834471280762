import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material/";
import { Alert } from "@mui/lab/";


import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";

import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";

import axios from 'axios';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Modules = (props) => {
  const [currentReport, setCurrentReport] = useState(false);
  const [preview, setPreview] = useState(false);

  const previewReport = (name) => {
    setPreview(false);
    setCurrentReport(name);
    axios({
      url: `${process.env.REACT_APP_API_URL}/reports/${name}?platform=backoffice&appId=${props.selectedApp.slug}`, //your url
      method: 'GET',
      responseType: 'json', // important
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("ubelong_admin_jwt")
      }
    })
      .then(function (response) {
        // handle success
        console.log(response);
        //alert(JSON.stringify(response.data.data));
        setPreview(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  const downloadReport = () => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/reports/${currentReport}?platform=backoffice&appId=${props.selectedApp.slug}&$limit=-1`, //your url
      method: 'GET',
      responseType: 'json', // important
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem("ubelong_admin_jwt")
      }
    })
      .then(function (response) {
        //Create a new Work Sheet using the data stored in an Array of Arrays.
        const workSheet = XLSX.utils.aoa_to_sheet(response.data.data);
        // Generate a Work Book containing the above sheet.
        const workBook = {
          Sheets: { data: workSheet, cols: [] },
          SheetNames: ["data"],
        };
        // Exporting the file with the desired name and extension.
        const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(fileData, 'report.xlsx');
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: "Informes" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Paper>
          <Box m={2}>
            <Typography component="h3" variant="h3">
              Criterio del informe
            </Typography>
          </Box>
          <Box m={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h3>Globales</h3>
                <Button onClick={() => previewReport('globalvv') }>Metricas VV</Button>
                <Button onClick={() => previewReport('globalapp')}>Metricas App</Button>
                <Button onClick={() => previewReport('users')}>Usuarios</Button>

                <h3>Contenido</h3>
                <Button onClick={() => previewReport('questions') }>Preguntas al ponente</Button>
                <Button onClick={() => previewReport('votes') }>Votaciones</Button>
                <Button onClick={() => previewReport('votingquestions')}>Votos por pregunta</Button>
                <Button onClick={() => previewReport('gamingInteractions')}>Interacciones de gamificación</Button>
                <Button onClick={() => previewReport('gamingQuestions')}>Votos por pregunta de gamificación</Button>

                <h3>Usuarios & interacción</h3>
                <Button onClick={() => previewReport('logins')}>Logins</Button>
                <Button onClick={() => previewReport('metas')}>Metas</Button>
                <Button onClick={() => previewReport('accesses') }>Accesos Fastpass</Button>
                <Button onClick={() => previewReport('networkingusers') }>Usuarios de networking</Button>
                <Button onClick={() => previewReport('networkingmeetings')}>Reuniones de networking</Button>
                <Button onClick={() => previewReport('agendaAttendees')}>Apuntados en agenda</Button>
              </Grid>
              <Grid item xs={6}>

              </Grid>
            </Grid>
          </Box>
        </Paper>

        {preview && (
          <Fragment>
            <Typography component="h3" variant="h3" mt={6}>
              Resultados (primeros 10)
            </Typography>
            <Box my={4}>
              <TableContainer component={Paper} className="DataTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      {preview[0].map(k => (
                        <TableCell align="left">{k}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preview.slice(1).map((item) => (
                      <TableRow key={item[2]}>
                        {item.map(k => (
                          <TableCell>{String(k)}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box my={2}>
              <Button
                //onClick={() => window.location = `${process.env.REACT_APP_API_URL}/reports/${currentReport}?$excel=true&appId=${props.selectedApp.slug}`}
                onClick={downloadReport}
                color="primary"
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                Descargar Excel
              </Button>
            </Box>
          </Fragment>
        )}
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Modules);
