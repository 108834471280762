import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../data/users/actions";

import userbase from 'userbase-js';

import {
  Container,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  TextField
} from "@mui/material/";
import { Alert } from "@mui/lab/";

import {
  CloudDownloadOutlined as DownloadIcon,
  CloudUploadOutlined as UploadIcon,
} from "@mui/icons-material";

import Dropzone from './components/Dropzone';

import NavBar from "../../components/NavBar";
import PageScreen from "../../components/PageScreen";

import axios from 'axios';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Modules = (props) => {
  const [userbaseCode, setUserbaseCode] = useState('');
  const [imported, setImported] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [successful, setSuccessful] = useState([]);
  const [failed, setFailed] = useState([]);


  const handleConfirm = async () => {

    if (userbaseCode != '') {
      await userbase.init({ appId: userbaseCode })
        .catch((e) => alert(JSON.stringify(e)));


      const toImport = imported.slice(1);
      setRecordCount(toImport.length);
      for (var i = 0, len = toImport.length; i < len; i++) {
        const object = {
          username: toImport[i][0],
          email: toImport[i][0],
          password: toImport[i][4].toString(),
          profile: {
          }
        };
        if (toImport[i][1]?.toString()) object.profile.firstName = toImport[i][1]?.toString();
        if (toImport[i][2]?.toString()) object.profile.lastName = toImport[i][2]?.toString();
        if (toImport[i][3]?.toString()) object.profile.subtitle = toImport[i][3]?.toString();
        await userbase.signUp({
          ...object,
          rememberMe: 'none'
        })
          .then((u) => {
            console.log("Success! Result:", u);
            setSuccessful((cs) => [...cs, object]);
          })
          .catch(e => {
            console.log("Error!", e);
            setFailed(cf => [...cf, object]);
          });
      }
    } else {
      alert("Userbase code required")
    }

  }

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Lite" },
        ]}
      />
      <PageScreen className="UsersImport">
        <Paper>
          <Box m={2}>
            <Typography component="h3" variant="h3">
              uBelong Lite
            </Typography>
          </Box>
          <Box m={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button onClick={() => { window.open('https://app.forestry.io/') } }>Forestry</Button>
                <Button onClick={() => { window.open('https://v1.userbase.com/') } }>Userbase</Button>
              </Grid>
              <Grid item xs={6}>

              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Typography component="h3" variant="h3">
          1- Asignar código Userbase
        </Typography>
        <Box my={2}>
          <TextField id="outlined-basic" variant="outlined" value={userbaseCode} onChange={e => setUserbaseCode(e.target.value)} />
        </Box>

        <Typography component="h3" variant="h3">
          2- Subir XLSX
        </Typography>
        <Box my={2}>
          <Dropzone setImported={setImported} />
        </Box>

        {imported && (
          <Fragment>
            <Typography component="h3" variant="h3">
              3- Revisar datos
                  </Typography>
            <Box my={4}>
              <TableContainer component={Paper} className="DataTable">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="left">Apellidos</TableCell>
                      <TableCell align="left">Subtítulo</TableCell>
                      <TableCell align="left">Contraseña</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {imported.slice(1).map((item) => (
                      <TableRow key={item._id}>
                        <TableCell component="th" scope="row">
                          {item[0]}
                        </TableCell>
                        <TableCell>{item[1]}</TableCell>
                        <TableCell>{item[2]}</TableCell>
                        <TableCell>{item[3]}</TableCell>
                        <TableCell>{item[4]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="h3" variant="h3">
              4- Confirmar
            </Typography>
            <Box my={2}>
              <Button
                onClick={() => handleConfirm()}
                color="primary"
                variant="contained"
                startIcon={<UploadIcon />}
              >
                Confirmar
                    </Button>
            </Box>
            <Box mt={2}>
              <LinearProgressWithLabel
                value={
                  successful.length > 0 || failed.length > 0
                    ? 100 *
                    (((successful.length + failed.length) * 1.0) /
                      recordCount)
                    : 0
                }
              />
            </Box>
            {(successful.length > 0 || failed.length > 0) && (
              <Box my={2}>
                <Alert severity="info">
                  Se han insertado {successful.length} usuarios y han
                        fallado {failed.length} de un total de{" "}
                  {successful.length + failed.length}.
                      </Alert>
              </Box>
            )}
            {failed && failed.length > 0 && (
              <Box my={2}>
                <Alert severity="error">
                  Inserciones fallidas
                        <ul>
                    {failed.map((f) => (
                      <li>{f.email}: ha fallado</li>
                    ))}
                  </ul>
                </Alert>
              </Box>
            )}
          </Fragment>
        )}
      </PageScreen>
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    users: state.data.users,
  }),
  (dispatch) => ({
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  })
)(Modules);
