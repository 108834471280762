import React, {} from 'react';

import {
  FormHelperText,
  InputLabel,
} from "@mui/material";

import MDEditor from '@uiw/react-md-editor';


export default props => {
  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        {props.definition.label}
      </InputLabel>
      <MDEditor
        autoFocus={false}
        style={{ marginTop: 15, marginBottom: 8}}
        value={props.value()}
        onChange={(v) => {
          props.onChange({ target: { value: v } })
        }}
      />
      <FormHelperText
        className={`${props.classes.helperText} ${
          props.error ? props.classes.error : null
        }`}
      >
        {props.error || props.definition.help}
      </FormHelperText>
    </React.Fragment>
  );
};