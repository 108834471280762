import * as api from "./api";
import * as actionTypes from "./actionTypes";

const update = (items, total) => ({
  type: actionTypes.UPDATE,
  items,
  total
});

export const append = (items, total) => ({
  type: actionTypes.APPEND,
  items,
  total
});

export const updateOne = (item) => ({
  type: actionTypes.UPDATE_ONE,
  item,
});

export const destroy = (item) => ({
  type: actionTypes.DESTROY,
  item,
});

const loading = () => ({
  type: actionTypes.LOADING
});

const setSelected = (item) => ({
  type: actionTypes.SET_SELECTED,
  item,
});

export const findItems = params => dispatch => {
  dispatch(loading());
  api
    .find(params)
    .then((r) => dispatch(r.data ? update(r.data, r.total) : update(r)))
    .catch((err) => {
      console.log('Error', err);
    });
}

export const findItemsAndAppend = params => dispatch => {
  dispatch(loading());
  api
    .find(params)
    .then((r) => dispatch(r.data ? append(r.data, r.total) : append(r)))
    .catch((err) => {
      console.log('Error', err);
    });
}

export const create = (payload) => (dispatch) => {
  dispatch(loading(true));
  return api.create(payload).then((r) => dispatch(append([r]))).catch(e => {
    dispatch(loading(false));
    throw(e);
  });
};

export const patch = (payload) => (dispatch) => {
  dispatch(loading(true));
  return api.patch(payload.slug, payload).then((r) => {
    dispatch(updateOne(r));
    dispatch(setSelected(r));
  }).catch(e => {
    dispatch(loading(false));
    throw (e);
  });
};

export const setSelectedFromSlug = (slug) => (dispatch) => {
  api.find({ query: { slug: slug } }).then((r) => {
    if (r.data.length > 0) {
      dispatch(setSelected(r.data[0]));
    } else {
      //window.location = '/';
    }
  });
};

export const createSlot = (id, payload) => (dispatch) => {
  return api
           .patch(id, {
             $push: { 'plugins.meetings.slots': payload },
           })
           .then((r) => {
             dispatch(setSelected(r));
           });
}

export const removeSlot = (id, slotId) => (dispatch) =>
         {
           return api
           .patch(
             id,
             {
               $pull: { 'plugins.meetings.slots': { _id: slotId } }
             }
           )
           .then((r) => dispatch(setSelected(r)));
         }