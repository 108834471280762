import React, {} from 'react';

import {
  Typography,
  Modal,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Fade,
  Backdrop,
} from "@mui/material";



export default props => {
  return (
    <div className="RightModal">
      <Box px={4} py={2.5}>
        <Typography component="h3" variant="h3">
          {props.newTitle}
        </Typography>
      </Box>
      <Paper className="ModuleTypes">
        <Box p={4}>
          <Grid container spacing={2}>
            {props.moduleTypes.map((mt) => (
              <Grid item md={6}>
                <div className="ModuleType" onClick={() => props.moduleTypeSelected(mt.key)}>
                  <div className="Title">
                    <span class={`icon-md-${mt.icon} Icon`}></span>
                    <Typography component="h4" variant="h5">
                      {mt.title}
                    </Typography>
                  </div>
                  <p>{mt.description}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}