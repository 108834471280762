import React, { useState } from 'react';

import {
  Chip
} from "@mui/material";

export default props => {

  const [checked, setChecked] = useState(props.checked);

  return (
    <Chip
      onClick={() => {
        props.onChange(!checked);
        setChecked(!checked);
      }}
      color="secondary"
      variant={checked ? "default" : "outlined"}
      size="small"
      label={props.label}
      className="Audience"
      style={{ marginBottom: 6 }}
    />
  );
}