import * as actionTypes from "./actionTypes";

const initialState = {
  feathersConnected: false,
  feathersAuthenticated: false,
  feathersReady: false,
  feathersUser: {},
  alert: {
    open: true
  },
  snack: {
    open: false,
    message:"ei"
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        ...action.app
      };
    case actionTypes.SET_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      }
    case actionTypes.SET_SNACK:
      return {
        ...state,
        snack: action.snack,
      };
    case actionTypes.CLOSE_SNACK:
      return {
        ...state,
        snack: initialState.snack
      }
    default:
      return state;
  }
};
