import React, { useState } from "react";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TextField
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import ChipCheckboxGroup from "../ChipCheckboxGroup";

//import { ColorPicker } from 'material-ui-color';

const COLORS = [
  "#F9885C",
  "#FFBD2C",
  "#C5B26D",
  "#74CE8E",
  "#4B9D49",
  "#64B1F4",
  "#7A75E8",
  "#E087E4",
  "#C15252",
];

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    color: "#686E71",
    fontSize: "0.916rem",
  },
  picker: {
    marginTop: 20,
    marginBottom: 10,
  },
  colors: {
    display: "flex",
    flexDirection: "row",
  },
  color: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 16,
    opacity: 0.7,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
    "&.active": {
      opacity: 1,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  helperText: {
    color: "#B4B4B7",
    fontSize: "0.6875rem",
  },
  error: {
    borderColor: "#FF6666",
    color: "#FF6666",
  },
}));

export default (props) => {
  const classes = useStyles();

  const [valueObject, setValueObject] = useState(props.value || {});

  console.log("Inside value", props.value);
  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        className={["MuiInputLabel-shrink", classes.label]}
      >
        {props.definition.label}
      </FormLabel>

      <div className={classes.picker}>
        <div className={classes.colors}>
          {COLORS.map((c) => (
            <div
              className={`${classes.color} ${props.value == c ? 'active' : null}`}
              onClick={ () => props.onChange( { target: { value: c } } ) }
              style={{ backgroundColor: c }}
            ></div>
          ))}
        </div>
      </div>
      {/* <ColorPicker deferred style={{ marginTop: 0 }} defaultValue="transparent" value={props.value} onChange={(c) => props.onChange( { target: { value: `#${c.hex}` } } ) } />*/}
      <TextField value={props.value} onChange={(c) => props.onChange( { target: { value: c.target.value } } ) } />
      <FormHelperText
        className={`${classes.helperText} ${
          props.error ? classes.error : null
        }`}
      >
        {props.error || props.definition.help}
      </FormHelperText>
    </FormControl>
  );
};
