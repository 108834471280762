import React, { Fragment, useEffect, useState } from "react";

import { Switch, Chip, IconButton, Grid, Button } from "@mui/material/";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material/";

import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import DataView from "../../components/DataView";
import RightModal from "../../components/RightModal/v2";

import { useConfirm } from "material-ui-confirm";

import { Link, useParams } from "react-router-dom";

// HOCs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modulesActionCreators from "../../data/modules/actions";
import HeaderButton from "../../components/HeaderButton";

import FeathersHelper from '../../helpers/feathers';

import Moment from "react-moment";
import "moment-timezone";
import nativeMoment from "moment-timezone";
import "moment/locale/es";

const BASE_QUERY = {
  $limit: 10,
  $sort: { position: 1 }
};

const VotingBlocks = (props) => {


  const MODAL_MANIFEST = [
    [
      {
        key: "active",
        type: "switch",
        label: "Activo",
        help: "Mostrar u ocultar el juego en la app",
      },
    ],
    [
      {
        key: "title",
        multilang: true,
        type: "string",
        label: "Título",
        help: "Título del juego.",
      },
    ],
    [
      {
        key: "questions",
        type: "nested",
        label: "Preguntas",
        add: "Añadir pregunta",
        children: [
          [
            {
              key: "title",
              multilang: true,
              type: "string",
              label: "Título",
              help: "Título de la pregunta",
            }
          ],
          [
            {
              key: "type",
              type: "select",
              label: "Tipo",
              help: "Tipo de pregunta",
              options: [
                { key: 'options', label: 'Opciones' },
                { key: 'rating', label: 'Valoración 1 al 5' },
                { key: 'open', label: 'Pregunta abierta' },
              ]
            }
          ],
          [
            {
              key: "options",
              type: "nested",
              label: "Opciones",
              add: "Añadir opción",
              children: [
                [
                  {
                    key: "title",
                    multilang: true,
                    type: "string",
                    label: "Título",
                    help: "Título de la opción",
                  }
                ]
              ]
            }
          ]
        ]
      },
    ],
  ];
  const MODAL_LANG_MANIFEST = [

  ];

  let { moduleId } = useParams();

  useEffect(() => {
    props.actions.modules.getItem(moduleId);
  }, []);

  const [currentItem, setCurrentItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleNew = () => {
    setCurrentItem({

    });
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    const newItem = { ...item };

    setCurrentItem(newItem);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentItem({});
  };

  const confirm = useConfirm();

  return (
    <Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: props.selectedApp.name, path: `/${props.selectedApp.slug}` },
          { title: props.modules.selectedItem.name },
        ]}
      />
      <PageScreen className="ListPage">
        <PageHeader
          title={props.modules.selectedItem.name}
          renderActions={() => (
            <Fragment>
              <HeaderButton onClick={handleNew}>+ Nuevo bloque</HeaderButton>
            </Fragment>
          )}
        />
        <Grid container spacing={2}>
          <Grid item md={8} lg={8}>
            <DataView
              items={(props.modules.selectedItem.votingBlocks||[]).sort( (a,b) => a.position > b.position ? 1 : -1)}
              columnDefs={[
                { ref: "title", align: "left" },
                //{ ref: "votes", align: "center" },
                { ref: "active", align: "center" },
                { ref: "projected", align: "center" },
                { ref: "reset", align: "center" },
                { ref: "actions", align: "left" },
              ]}
              renderHeader={(ref) => {
                switch (ref) {
                  case "title":
                    return "Título";
                  case "votes":
                    return "Votos";
                  case "active":
                    return "Activo";
                  case "projected":
                    return "Proyectar";
                  case "reset":
                    return "Reiniciar";
                  case "actions":
                    return "Acciones";
                  default:
                    return null;
                }
              }}
              renderCell={(ref, item) => {
                switch (ref) {
                  case "title":
                    return item[ref][props.defaultLang];
                  case "votes":
                    return item.questions.length;
                  case "active":
                    return (
                      <Switch
                        color="primary"
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={(event) =>
                          props.actions.modules.patchBlock(moduleId, {
                            ...item,
                            active: event.target.checked,
                          })
                        }
                        checked={item.active}
                      />
                    );
                  case "projected":
                    return (
                      item.questions.filter( q => q.projected ).length > 0 ?
                        <Button onClick={async () => {
                          await props.actions.modules.patch({ _id: moduleId }, {
                            "votingBlocks.$[].questions.$[].projected": false
                          }, { query: { _type: 'votingModules' } });
                        }} variant="contained" color="primary">Proyectada</Button>
                        :
                        <Button onClick={async () => {
                          await props.actions.modules.patch({ _id: moduleId}, {
                            "votingBlocks.$[].questions.$[].projected": false
                          }, { query: { _type: 'votingModules' } });
                          props.actions.modules.patchBlock(moduleId, {
                            ...item,
                            questions: item.questions.map( (q,i) => i == 0 ? { ...q, projected: true } : q )
                          })
                        }} variant="outlined">Proyectar</Button>
                    );
                  case "reset":
                    return (
                      <Button onClick={async () => {
                        await confirm({
                          title: "¿Reiniciar votos?",
                          description: `Esta acción pondrá todos los votos de todas las preguntas del bloque a 0, y no se puede deshacer.`,
                          confirmationText: "Reiniciar",
                          confirmationButtonProps: { variant: "contained" },
                          cancellationText: "Cancelar",
                          cancellationButtonProps: { variant: "contained" },
                        });
                        await FeathersHelper.patch("votes", null, {
                          deleted: true
                        }, {
                          query: {
                            moduleId,
                            questionId: { $in: item.questions.map( q => q._id ) }
                          }
                        });
                      }} variant="outlined">Reiniciar</Button>
                    );
                  case "actions":
                    return (
                      <Fragment>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="EditButton"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className="DeleteButton"
                          onClick={() => {
                            confirm({
                              title: "¿Eliminar bloque?",
                              description: `Esta acción eliminará por completo el
                              bloque.
                              Esta acción es permenente y no tiene marcha atrás.`,
                              confirmationText: "Eliminar",
                              confirmationButtonProps: { variant: "contained" },
                              cancellationText: "Cancelar",
                              cancellationButtonProps: { variant: "contained" },
                            })
                              .then(() => {
                                props.actions.modules.removeBlock(moduleId, item._id);
                              })
                              .catch(() => {});
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Fragment>
                    );
                  default:
                    return null;
                }
              }}
              empty={{
                title: "Añade bloques a tu módulo",
                body:
                  "Construye rápidamente y previsualiza al instante todas las funciones están incluidas, solo cree y publique go live en minutos y comience a incorporar asistentes",
                cta: "+ Crear nuevo bloque",
                action: handleNew,
              }}
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <div className="VotingPreview">
              <div className="Header">
                <a href="#">Anterior</a>
                <span>1/3</span>
                <a href="#">Siguiente</a>
              </div>
              <iframe src={`${props.selectedApp.slug}/projections/${moduleId}`} />
            </div>
            <Link to={`../../../projections/${moduleId}`} target="_blank">Abrir proyección</Link>
          </Grid>
        </Grid>
      </PageScreen>
      <RightModal
        newTitle="Crear bloque"
        editTitle="Editar bloque"
        open={modalOpen}
        currentItem={currentItem}
        handleClose={handleClose}
        manifest={MODAL_MANIFEST}
        langManifest={MODAL_LANG_MANIFEST}
        createAction={(payload) => {
          console.log("PAYLOAD", payload)
          return props.actions.modules.createBlock(moduleId, payload)
        }}
        patchAction={(currentItem, payload) =>
          props.actions.modules.patchBlock(moduleId, payload)
        }
      />
    </Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
    modules: state.data.modules,
    services: {
      app: state.services.app
    },
    defaultLang: 'es'
  }),
  (dispatch) => ({
    actions: {
      modules: bindActionCreators(modulesActionCreators, dispatch),
    },
  })
)(VotingBlocks);
