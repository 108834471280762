import FeathersHelper from './feathers';
import axios from "axios";

const getHash = async (blob, algo = "SHA-256") => {
  // convert your Blob to an ArrayBuffer
  // could also use a FileRedaer for this for browsers that don't support Response API
  const buf = await new Response(blob).arrayBuffer();
  const hash = await crypto.subtle.digest(algo, buf);
  let result = '';
  const view = new DataView(hash);
  for (let i = 0; i < hash.byteLength; i += 4) {
    result += view.getUint32(i).toString(16).padStart(2, '0');
  }
  return result;
}

export const uploadFile = async (file, width = 300, height = 300, fit = "inside", isPublic = false, setProgress = (percent) => {}) => {

  try {

    const fileExt = file.name.split('.').pop();
    console.log(1)
    const hash = await getHash(file)
    const { key, signedUrl, signedResizedURL, ContentType } = await FeathersHelper.getUploadUrl(`${hash}.${fileExt}`, file.type, width, height, fit, isPublic);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    };
    console.log(3)
    try {
      const ar = await axios({
        method: 'put',
        url: signedUrl,
        data: file,
        ...config
      })
    } catch (e) {
      console.log("ERRO", e)
    }
    console.log(4)
    return {
      key,
      signedUrl,
      signedResizedURL
    }

  } catch (e) {
    console.log(e)
  }

}