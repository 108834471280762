import React, {} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
  List as ListIcon,
  AttachFile as AttachmentIcon,
  Public as WebtabIcon,
  VerticalSplit
} from "@mui/icons-material/";

import { ReactComponent as DragSvg } from '../../../../img/draggable.svg';

const Icon = props => {
  switch(props.type) {
    case 'list':
      return <ListIcon {...props} />;
    case 'attachment':
      return <AttachmentIcon {...props} />
    case 'external':
      return <WebtabIcon {...props} />
    case 'general':
      return <VerticalSplit {...props} />
  }
}

// STYLING
const useStyles = makeStyles((theme) => ({
  wrap: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    padding: "10px 10px 10px 14px",
    marginRight: 10,
    color: '#2A2827',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    fontSize: 14,
    marginRight: 10
  },
  text: {
    flex: 1
  },
  dragger: {
    marginLeft: 10
  }
}));

export default ({ content, ...props }) => {

  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <Icon type={content.contentType} className={classes.icon} />
      <span className={classes.text}>{content.title.es}</span>
      <DragSvg className={classes.dragger} />
    </div>
  );
}