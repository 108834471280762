import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton,
  Button
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  QrCode as QRIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";



function getFullName(params) {
  return params.getValue ? `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }` : 'caca';
}

export default props => {

  useEffect(() => {
  }, []);

  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar administradores?",
          description: `Esta acción eliminará por completo los
          administradores, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => {});
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'email',
          header: 'Email',
        },
        {
          key: 'firstName',
          header: 'Nombre'
        },
        {
          key: 'lastName',
          header: 'Apellidos'
        },
        {
          key: 'company',
          header: 'Empresa',
        },
        {
          key: 'password',
          header: 'Contraseña',
          value: (item) => '',
          importValue: (item) => {
            return item.password ? item.password.toString() : item.password;
          }
        },
        {
          key: 'master',
          header: 'Superadmin',
          value: (item) => item.master ? 'Si' : 'No',
          importValue: (item) => item.master == 'Si'
        }
      ]}
      importFormatter={(items) => {
        return items.map( (i) => {
          const { password, ...rest } = i;
          if (password == '') return rest;
          else return i;
        });
      }}
      humanize={(item) => `${item.email}`}
      importHandler={true}
      // IMPORT EXPORT END
      extraActions={(selectedRows) => {
        return null
      }}
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "firstName",
          headerName: "Nombre",
          flex: 2
        },
        {
          field: "lastName",
          headerName: "Apellidos",
          flex: 2
        },
        { field: "email", headerName: "E-mail", flex: 1 },
        { field: "company", headerName: "Empresa", flex: 1 },
        {
          field: "master",
          headerName: "Superadmin",
          valueGetter: (params) => params.value ? 'Si' : 'No',
          flex: 1
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar administrador?",
                      description: `Esta acción eliminará por completo el
                      administrador, así como todo su contenido asociado.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => {});
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "Añade tu primer administrador",
        body:
          "Añade administradores y permíteles así acceder con sus credenciales.",
        cta: "+ Crear nuevo administrador",
        action: props.handleNew,
      }}
    />
  );
}