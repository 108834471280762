
export const query = {
  $aggregation: [
    { "$lookup": { "from": "audiences", "localField": "audiences", "foreignField": "_id", "as": "fullAudiences"} }
  ],
  $sort: { lastName: 1 },
  $limit: -1
};

export const modalManifest = [
  [
    {
      key: "invisible",
      type: "switch",
      label: "Usuario invisible",
    },
  ],
  [
    {
      key: "hideFromRanking",
      type: "switch",
      label: "Esconder en rankings",
    },
  ],
  [
    {
      key: "fastpassEnabled",
      type: "switch",
      label: "Habilitar Fastpass",
    },
  ],
  [
    {
      key: "email",
      type: "string",
      label: "Email",
    },
  ],
  [
    {
      key: "firstName",
      type: "string",
      label: "Nombre",
    },
  ],
  [
    {
      key: "lastName",
      type: "string",
      label: "Apellidos",
    },
  ],
  [
    {
      key: "subtitle",
      type: "string",
      label: "Subtítulo",
    },
  ],
  [
    {
      key: "password",
      type: "string",
      label: "Contraseña",
    },
  ],
  [
    {
      key: "bio",
      type: "text",
      label: "Biografía",
    },
  ],
  [
    {
      key: "audiences",
      type: "multiAudience",
      label: "Audiencias",
      help: 'Elige a qué audiencias pertenece este usuario'
    },
  ]
];